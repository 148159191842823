// Example client which will connect to the socket.io server with websockets

import { Manager } from 'socket.io-client';

export default class ListenClient {

    constructor(address) {
        this.registrations = {};

        this.connectionRegistrations = {}; // Callbacks for the connection status
        this.connectionStatus = "WAITING";
        // WAITING - Waiting for connect
        // CONNECTING - Trying to connect
        // LOGIN_FAILED - Login has failed so we gave up trying to connect
        // CONNECTED - We are connected
        // SHUTDOWN - Manager is shutdown

        this.address = address;
        this.serverHash = null;
        this.isShutdown = false;

        this.userToken = null; // What the client is configured to use to login
        this.connectionMeta = {};

        this._reg = {}; // This is where we store the global reg data for all logged in users
        // Changes here get reported to listeners via the reportRegs event

        this.socket = null;
    }

    listenErrors(callback) {
        this.errorCallback = callback;
    }
    unListenErrors() {
        delete this.errorCallback;
    }
    reportErrorToBackend(title, text) {
        this.errorCallback.apply(this, [title, text]);
    }

    setUserToken(token) {
        // console.log("LC Set token to ", token);
        this.userToken = token;

        /*
        if (this.socket) {
            if (this.socket.connected) {
                this.reconnect(); // This should trigger a reconnection so that the logs in with the new user token.
            }
        }
        */
    }

    connect() {
        // console.log("LC connecting...");

        this.isShutdown = false;

        // Check if already connected
        if (this.manager) {
            this.manager.disconnect();
            this.manager = null;
        }

        // console.log("LC.connect was called, setting status to CONNECTING");
        this.setNewConnectionStatus("CONNECTING");
        this.manager = new Manager(this.address, {
            reconnectionDelayMax: 10000,
            transports: ['polling'],
            withCredentials: true,
        });
        this.manager.on('error', (e) => {
            console.error(e.message);
        });

        this.socket = this.manager.socket("/");

        this.socket.on('connect', () => {
            // console.log("We are connected");
            this.socketConnected(this.socket.id);
        });
        this.socket.on('disconnect', () => {
            // console.log("We are DISconnected");
            this.socketDisconnected();
        });

        
        this.socket.on('setView', (updateEvent) => {
            // Process incoming data to set one of the views
            this.setView(updateEvent);
        });
        this.socket.on('viewFieldsSet', (updateEvent) => {
            // Process incoming data to update one of the views
            this.viewFieldsSet(updateEvent);
        });
        this.socket.on('userUpdated', (updateEvent) => {
            // Just save this new logged in user
            this.setNewConnectionStatus("CONNECTED", { 
                user: updateEvent.newLoggedInUser,
            });
        });
        this.socket.on('userDisabled', () => {
            // This user has been disabled, so we will be disconnected soon
            this.reportErrorToBackend("Error", "Your account has been disabled by a User administrator")
        })
        this.socket.on('reportRegs', (updateEvent) => {
            // Process incoming data to report the current registrations

            if (updateEvent.regsType === "all") {
                // Full update in regs field
                this._regs = updateEvent.regs;

            } else if (updateEvent.regsType === "socketFirstView") {
                // socketId, loggedInUser, viewId, view
                const socketId = updateEvent.socketId;
                const loggedInUser = updateEvent.loggedInUser;
                const viewId = updateEvent.viewId;
                const view = updateEvent.view;
                const views = {};
                views[viewId] = view;
                this._regs[socketId] = {
                    loggedInUser,
                    views,
                }

            } else if (updateEvent.regsType === "socketViewAdd") {
                // socketId, viewId, view
                const socketId = updateEvent.socketId;
                const viewId = updateEvent.viewId;
                const view = updateEvent.view;

                if (socketId in this._regs) {
                    this._regs[socketId].views[viewId] = view;
                } else {
                    console.warn("Hang about, we have never heard of socket " + socketId + " before!!!! socketViewAdd failed");
                }

            } else if (updateEvent.regsType === "socketViewRemove") {
                // socketId, viewId
                const socketId = updateEvent.socketId;
                const viewId = updateEvent.viewId;

                if (socketId in this._regs) {
                    if (viewId in this._regs[socketId].views) {
                        delete this._regs[socketId].views[viewId];
                    } else {
                        console.warn("Hang about, we have never heard of view " + viewId + " before!!!!");
                    }
                } else {
                    console.warn("Hang about, we have never heard of socket " + socketId + " before!!!! socketViewRemove failed");
                }

            } else if (updateEvent.regsType === "socketRemove") {
                // socketId
                const socketId = updateEvent.socketId;

                if (socketId in this._regs) {
                    delete this._regs[socketId];
                } else {
                    console.warn("Hang about, we have never heard of socket " + socketId + " before!!!! socketRemove failed");
                }

            }

            // Report the current regs
            this.reportRegs({
                regs: this._regs,
            });
        });

        this.manager.connect();
    }

    shutdown() {
        // console.log("LC Shutdown");
        this.setNewConnectionStatus("SHUTDOWN");
        this.isShutdown = true;
        if (this.manager) {
            this.manager.disconnect();
        }
    }

    reset() {
        if (this.socket && this.socket.connected) {
            this.socket.close();
        }
    }

    reconnect() {
        if (this.socket) {
            this.socket.close();
        }
        if (!this.isShutdown && this.connectionStatus !== "WAITING") {
            console.log("Reconnecting in 3 seconds...");
            setTimeout(() => {
                this.connect();
            }, 3 * 1000);
        }
    }

    listenConnection(listenId, callback) {
        const regObj = {
            id: listenId,
            listener: callback,
        };
        this.connectionRegistrations[listenId] = regObj;

        // Send current connection status and meta
        callback.apply(this, [{
            status: this.connectionStatus,
            meta: this.connectionMeta,
        }]);
    }
    unlistenConnection(listenId) {
        delete this.connectionRegistrations[listenId];
    }
    setNewConnectionStatus(newStatus, newMeta = {}) {
        // console.log("New status", newStatus, newMeta, this.isShutdown);
        if (this.isShutdown) {
            return;
        }
        // Previous status
        const prevStatus = this.connectionStatus;
        // Previous meta
        const prevMeta = JSON.stringify(this.connectionMeta);

        // console.log("Setting new connection status on LC", newStatus, newMeta);
        this.connectionStatus = newStatus;
        if (newStatus === "CONNECTED") {
            if (newMeta) {
                // Assign keys only
                this.connectionMeta = { ...this.connectionMeta, ...newMeta};
            }
        } else {
            // Reset connection meta if we are NOT connected
            this.connectionMeta = newMeta;
        }

        if (this.connectionStatus !== prevStatus || JSON.stringify(this.connectionMeta) !== prevMeta ) {
            // Only fire if there are specific changes with the connection status or meta
            this.fireConnectionStatusCallbacks();
        }
    }
    fireConnectionStatusCallbacks() {
        Object.keys(this.connectionRegistrations).forEach(listenId => {
            if (listenId in this.connectionRegistrations) {
                if (typeof this.connectionRegistrations[listenId] !== "undefined") {
                    const reg = this.connectionRegistrations[listenId];
                    reg.listener.apply(this, [{
                        status: this.connectionStatus,
                        meta: this.connectionMeta,
                    }]);
                }
            }
        });
    }

    listen(listenId, listenType, listenParams, callback) {
        const regObj = {
            id: listenId,
            type: listenType,
            params: listenParams,

            // loaded: false,
            // data: null,
            lastChange: null,
            listeners: [callback],
        };
        this.registrations[listenId] = regObj;

        // this.registrations[listenId].listeners.push(callback);

        if (this.socket) {
            if (this.socket.connected) {
                // Already connected
                this.sendListenForReg(regObj);
            }
        }

        // return this.registrations[listenId];
    }

    async unlisten(listenId) {
        // We MUST remove the registration instantly
        delete this.registrations[listenId];

        // Attempt to tell the other side that we have stopped listening, but we don't worry if it doesnt get through.
        try {
            await this.emitAction("unlisten", {
                id: listenId,
            });
        } catch(e) {
            // Ignore all errors
        }
    }

    async socketConnected(connectionId) {

        this.setNewConnectionStatus("CONNECTED", { connectionId });
        const tokenUsedForLogin = this.userToken;
        try {
            // Login first
            const response = await this.emitAction("login", {
                bearer: tokenUsedForLogin,
            });

            // console.log("LOGIN RESPONSE", response);

            this.setNewConnectionStatus("CONNECTED", {
                // token: tokenUsedForLogin, 
                token: response.token,
                expiry: response.tokenExpiry,
                user: response.user,
                orgs: response.orgs,
            });

            this.setServerHash(response.serverHash);

            // Emit ALL listen registrations now
            const regKeys = Object.keys(this.registrations);
            for(let i=0; i<regKeys.length; i++) {
                const regObj = this.registrations[regKeys[i]];
                this.sendListenForReg(regObj); // Dont await here because we don't need to handle failure of this here really
            }
        } catch(e) {
            // If login fails, we should set this error on the app state and display the error message on the login page.
            console.warn(e);

            // this.reconnect();
            this.socket.close();

            this.setNewConnectionStatus("LOGIN_FAILED", {
                reason: e.message,
            });
        }
    }

    setServerHash(hash) {
        // If this changes from a non null value, we know to NULL out all reg lastUpdate timestamps so that a full fetch is sent when we start listening.
        if (hash !== this.serverHash) {
            // console.log("Server hash has CHANGED from: " + this.serverHash + " to " + hash);
            // Note, we clear the known last change so that we receive possible new versions of data models since server restart.
            Object.keys(this.registrations).forEach(regKey => {
                const reg = this.registrations[regKey];
                reg.lastChange = null;
            });
        }
        // console.log("Server hash is: " + hash);
        this.serverHash = hash;
    }

    async sendListenForReg(reg) {
        // Get the last change from the view
        const lastChange = this.registrations[reg.id].lastChange;
        // console.log("REG PARAMS 2", reg.params);
        try {
            return await this.emitAction("listen", {
                id: reg.id,
                type: reg.type,
                params: reg.params,
                lastChange: lastChange,
            });
        } catch(e) {
            // Something went wrong which we should tell the backend about if it is registered
            console.warn(e);
            this.reportErrorToBackend("Listening Error", e.message);
        }
    }

    socketDisconnected() {
        if (!this.isShutdown) {
            // console.log("SOCKET Disconnected (setting connection status to CONNECTING)...");
            this.setNewConnectionStatus("CONNECTING");
        }
    }

    setView(updateEvent) {
        const reg = this.registrations[updateEvent.viewId];

        // Must check that the registration exists still first
        if (reg) {
            // Make sure we update lastChange in the view here
            reg.lastChange = updateEvent.lastChange;

            // Make sure we call the callbacks at this.views[id].listeners
            reg.listeners.forEach(l => {
                l.apply(this, ['setView', updateEvent]);
            });
        }
    }

    viewFieldsSet(updateEvent) {
        // console.log("Received viewFieldsSet", updateEvent);
        const reg = this.registrations[updateEvent.viewId];

        // Must check that the registration exists still first
        if (reg) {

            reg.lastChange = updateEvent.lastChange;
            // Make sure we call the callbacks at this.views[id].listeners
            reg.listeners.forEach(l => {
                l.apply(this, ['viewFieldsSet', updateEvent]);
            });
        }
    }

    reportRegs(updateEvent) {
        // Send to ALL the LC regs
        Object.values(this.registrations).forEach(reg => {
            reg.listeners.forEach(l => {
                l.apply(this, ['reportRegs', updateEvent]);
            });
        })
    }

    async emitAction(type, params, timeout = 10) {
        let finished = false;
        const emittedSocketId = this.socket.id;

        return new Promise((resolve, reject) => {
            if (this.socket === null) {
                reject(new Error("Manager is not started/connected, action failed to send: " + type));
            } else if (!this.socket.connected) {
                reject(new Error("Socket is not connected, action failed to send: " + type));
            } else {
                this.socket.emit(type, params, (response) => {
                    // console.log("ACK received", response);
                    if (!finished) {
                        if (typeof response === "object" && response.error) {
                            finished = true;
                            reject(new Error(response.error));
                        } else if (typeof response === "object" && response.data) {
                            finished = true;
                            resolve(response.data);
                        } else {
                            finished = true;
                            reject(new Error("Unknown response object: " + JSON.stringify(response)));
                        }
                    }
                });
                setTimeout(() => {
                    if (!finished) {
                        finished = true;
                        if (this.socket === null) {
                            reject(new Error("Manager disconnected before we received confirmation"));
                        } else if (!this.socket.connected) {
                            reject(new Error("Manager disconnected before we received confirmation"));
                        } else {
                            if (emittedSocketId === this.socket.id) {
                                // We are still connected to the same socket, but didn't get any result in time
                                reject(new Error("Action timed out: " + type));
                            } else {
                                // reject(new Error("Manager has reconnected a different socket in the meantime"));
                                // I want to hide this error
                                resolve(null);
                            }
                        }
                    }
                }, timeout * 1000);
            }
        });
    }
}


