import React from 'react';
import { DragDrop } from '../DragDrop';
import './styles.scss';
import { BackendContext } from '../BackendContext';
import UserRegistrations from '../UserRegistrations';
import { PreviewPane } from '../PreviewPane';
import { Text, TabNav, TabNavItem } from 'vcc-ui';
import { ConfigPane } from '../ConfigPane';
import { PublishPane } from '../PublishPane';
import { Link } from 'react-router-dom';
import leftArrow from '../../assets/left-arrow.svg';
import LoginRequired from '../LoginRequired';
import PermissionRequired from '../PermissionRequired';
import { getEndpoint } from '../../lib/endpoint';

const backendEndpoint = getEndpoint();

class ChecklistEdit extends React.Component {

    constructor(props) {
        super(props);

        const tabId = this.getTabIdParam();

        this.state = {
            connectionStatus: null,
            checklistVersion: null,
            selectedTab: tabId,
        };
    }

    getCurrentlyLoggedInUserFromConnectionStatus() {
        if (this.state.connectionStatus) {
            if (this.state.connectionStatus.meta) {
                if (this.state.connectionStatus.meta.user) {
                    const idp = this.state.connectionStatus.meta.user.idp;
                    if (idp !== "none") {
                        return this.state.connectionStatus.meta.user;
                    }
                }
            }
        }
        return null;
    }

    getOrganisationSlug() {
        let slug = null;
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.org) {
                    slug = this.props.match.params.org;
                }
            }
        }
        return slug;
    }

    getChecklistVersionIdProp() {
        // Use this prop
        let clvId = this.props.checklistVersionId;

        // Or override using the path match param (if it exists)
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.id) {
                    clvId = this.props.match.params.id;
                }
            }
        }
        return clvId;
    }

    getTabIdParam() {
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.tabId) {
                    return this.props.match.params.tabId;
                }
            }
        }
        return "checks"; // Default, if not set
    }

    componentDidMount() {

        const checklistVersionId = this.getChecklistVersionIdProp();

        // Tell the backend about this component starting up
        this.backendRef = this.context.backend.register("checklistVersion", {
            checklistVersionId: checklistVersionId,
        }, (reducerCallback) => {
            // The reducerCallback takes in the old state and returns the new state
            this.setState((oldState) => {
                const oldCLV = oldState.checklistVersion;
                const newCLV = reducerCallback(oldCLV);
                if (newCLV) {
                    // Special case where the reducer returns null/undefined, don't do anything
                    return {
                        ...oldState,
                        checklistVersion: newCLV,
                    }
                }
            });
        }, (newConnectionStatus) => {
            this.setState((oldState) => {
                return {
                    ...oldState,
                    connectionStatus: newConnectionStatus,
                }
            });
        });

        this.unlisten = this.props.history.listen((record, type) => {
            if (type === "POP") {
                const result = /\/([^/]+)$/.exec(record.pathname);
                if (result) {
                    let tabId = result[1];
                    if (['checks', 'notes', 'config', 'preview', 'publish'].indexOf(tabId) === -1) {
                        tabId = 'checks';
                    }
                    this.changeTab(tabId, false);
                }
            }
        });
    }
  
    componentWillUnmount() {
        this.context.backend.unRegisterData(this.backendRef);
        this.unlisten();
    }

    getOrderedRowItems = () => {
        
        // Checklist object must exist in state
        if (this.state.checklistVersion === null || !this.state.checklistVersion.rowItemsOrder) {
            return [];
        }

        // Order array must be unique
        const rowItemsOrder = this.state.checklistVersion.rowItemsOrder.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        const result = [];
        // First add items in the order list
        rowItemsOrder.forEach(itemId => {
            if (itemId in this.state.checklistVersion.rowItems) {
                result.push({ ...this.state.checklistVersion.rowItems[itemId], id: itemId });
            }
        });

        // Should we also append all the items which do not appear in the order array here? No (for now)

        return result;
    }

    getOrderedNotes = () => {
        // Checklist object must exist in state
        if (this.state.checklistVersion === null || !this.state.checklistVersion.notesOrder) {
            return [];
        }

        // Order array must be unique
        const notesOrder = this.state.checklistVersion.notesOrder.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        const result = [];
        // First add items in the order list
        notesOrder.forEach(itemId => {
            if (itemId in this.state.checklistVersion.notes) {
                result.push({ ...this.state.checklistVersion.notes[itemId], id: itemId });
            }
        });

        // Should we also append all the items which do not appear in the order array here? No (for now)

        return result;
    }

    getChecklistName = () => {
        // Checklist object must exist in state
        if (this.state.checklistVersion === null || !this.state.checklistVersion.checklist) {
            return "";
        } else {
            return this.state.checklistVersion.checklist.name;
        }
    }

    getCurrentTemplate = () => {
        if (this.state.checklistVersion === null || !this.state.checklistVersion.config) {
            return null;
        } else {
            const templates = this.getPossibleTemplates();
            const currentTemplateId = this.state.checklistVersion.config.template;
            const appropriate = templates.filter(t => {
                if (t.value === currentTemplateId) {
                    return true;
                } else {
                    return false;
                }
            });
            if (appropriate.length === 1) {
                return appropriate[0];
            } else {
                return null;
            }
        }
    }

    getIsEditable = () => {
        if (this.state.checklistVersion === null || !this.state.checklistVersion.isEditable) {
            return null;
        } else {
            return this.state.checklistVersion.isEditable;
        }
    }

    getCurrentVersionNumber = () => {
        if (this.state.checklistVersion === null || !this.state.checklistVersion.versionNumber) {
            return null;
        } else {
            return this.state.checklistVersion.versionNumber;
        }
    }

    getCurrentTemplateVariables = () => {
        if (this.state.checklistVersion === null || !this.state.checklistVersion.templateVariables) {
            return [];
        } else {
            return this.state.checklistVersion.templateVariables;
        }
    }

    getOverriddenVariables = () => {
        if (this.state.checklistVersion === null || !this.state.checklistVersion.overriddenVariables) {
            return [];
        } else {
            return this.state.checklistVersion.overriddenVariables;
        }
    }

    getPossibleTemplates = () => {
        const org = this.props.organisation;
        if (org && org.templates && org.templates.length > 0) {
            return org.templates.map(id => ({
                label: id,
                value: id,
            }));
        }
        return [
            {
                "label": "Volvo Selekt v1",
                "value": "volvo-selekt-v1",
            },
            {
                "label": "Polestar v1",
                "value": "polestar-v1",
            },
        ]
    }

    /*
    selectItemAndRefreshPreview = (highlightId) => {
        this.setState({
            highlightId: highlightId
        });
    }
    */

    getMyConnectionId() {
        if (this.state.connectionStatus === null || !this.state.connectionStatus.meta || this.state.connectionStatus.meta === null) {
            return null;
        } else {
            return this.state.connectionStatus.meta.connectionId;
        }
    }
    getUserOnThisPage() {
        if (this.state.checklistVersion === null || !this.state.checklistVersion._regs) {
            return {};
        } else {
            // Find the sockets that have at least one view matching this page
            const list = {};
            Object.keys(this.state.checklistVersion._regs).map(socketId => {
                const reg = this.state.checklistVersion._regs[socketId];
                let hasAppropriateView = false;
                Object.keys(reg.views).forEach(viewId => {
                    const view = reg.views[viewId];
                    if (view.listenType === "checklistVersion" && view.listenParams.checklistVersionId === this.state.checklistVersion._id) {
                        hasAppropriateView = true;
                    }
                })
                if (hasAppropriateView) {
                    list[socketId] = reg;
                }
                return null;
            });
            return list;
        }
    }

    changeTab(tabName, pushState = true) {
        const organisationSlug = this.getOrganisationSlug();
        this.setState((oldState) => {
            return {
                ...oldState,
                selectedTab: tabName,
            }
        });
        if (pushState) {
            // Push history
            const clvId = this.getChecklistVersionIdProp();
            this.props.history.push("/" + organisationSlug + "/master/edit/" + clvId + "/" + tabName);
        }
    }

    downloadUrl(type, checklistVersionId) {
        return backendEndpoint + "/generate/" + type + "/" + checklistVersionId + "?ts=" + (new Date()).getTime();
    }

    render() {
        // console.log("History changed", this.props.history);

        const rowItems = this.getOrderedRowItems();
        const notes = this.getOrderedNotes();
        const checklistVersionId = this.getChecklistVersionIdProp();
        const checklistName = this.getChecklistName();

        const currentTemplate = this.getCurrentTemplate();
        const possibleTemplates = this.getPossibleTemplates();
        const templateVariables = this.getCurrentTemplateVariables();
        const overriddenVariables = this.getOverriddenVariables();

        const backend = this.context.backend;

        const usersOnThisPage = this.getUserOnThisPage();
        const myConnectionId = this.getMyConnectionId();
        const isEditable = this.getIsEditable();
        const versionNumber = this.getCurrentVersionNumber();

        const organisationSlug = this.getOrganisationSlug();

        let numberOfEditorsApartFromYou = 0;
        Object.keys(usersOnThisPage).forEach(connectionId => {
            if (myConnectionId !== connectionId) {
                numberOfEditorsApartFromYou++;
            }
        });

        const organisation = this.props.organisation;
        const user = this.getCurrentlyLoggedInUserFromConnectionStatus();
        // Note: Organisation should always be set on a page with org slug in the url
        if (organisation === null) {
            return null;
        }
        
        if (user === null) {
            return (
                <div className="users">
                    <LoginRequired history={this.props.history} />
                </div>
            )
        } else {
            // We are logged in, check organisation permissions
            if (user.permissions.organisations[organisation._id].isMasterEditor !== true) {
                return (
                    <div className="users">
                        <PermissionRequired history={this.props.history} permissionName={organisation.name + ": Master Editor"} />
                    </div>
                )
            }
        }

        const pdfDownload = this.downloadUrl("pdf", checklistVersionId);
        const excelDownload = this.downloadUrl("excel", checklistVersionId);
        
        return (
            <div className="checklistEdit">

                <div className="userRegWrapper">
                    {isEditable && (
                        <UserRegistrations myConnectionId={myConnectionId} regs={usersOnThisPage} alignRight={true} showWarningWhenSomebodyElseEditing="this checklist." />
                    )}
                </div>

                <Link to={"/" + organisationSlug + "/master/list"} className="backBtnWrapper">
                    <div className="backBtn">
                        <img src={leftArrow} alt="back" />
                        <Text as="p" subStyle="emphasis">Back</Text>
                    </div>
                </Link>

                <div className="checklistTitle">
                    <Text subStyle="emphasis">{isEditable ? "Editing" : "Viewing"} Checklist: {checklistName}</Text>
                </div>
                
                <Text style={{marginBottom: '20px'}}>Version: {versionNumber}</Text>
                
                <div className="navWrapper">
                    <TabNav enableLineTransition textAlign={'left'}>
                        <TabNavItem
                            isActive={this.state.selectedTab === 'checks'}
                            onClick={() => this.changeTab('checks')}
                        >
                            Checks
                        </TabNavItem>

                        <TabNavItem
                            isActive={this.state.selectedTab === 'notes'}
                            onClick={() => this.changeTab('notes')}
                        >
                            Notes
                        </TabNavItem>

                        <TabNavItem
                            isActive={this.state.selectedTab === 'config'}
                            onClick={() => this.changeTab('config')}
                        >
                            Config
                        </TabNavItem>

                        <TabNavItem
                            isActive={this.state.selectedTab === 'preview'}
                            onClick={() => this.changeTab('preview')}
                        >
                            Preview
                        </TabNavItem>

                        <TabNavItem
                            isActive={this.state.selectedTab === 'publish'}
                            onClick={() => this.changeTab('publish')}
                        >
                            Publish
                        </TabNavItem>
                    </TabNav>
                </div>

                <div className="tabPane">
                    { this.state.selectedTab === "checks" && (
                        <DragDrop 
                            listType="rowItems"
                            listData={rowItems}
                            addItem={(...args) => backend.addRowItem(checklistVersionId, ...args)}
                            reorderItems={(...args) => backend.reorderRowItems(this.backendRef, checklistVersionId, ...args)}
                            deleteItem={(...args) => backend.deleteRowItem(checklistVersionId, ...args)}
                            updateItem={(...args) => backend.updateRowItem(checklistVersionId, ...args)}
                        />
                    )}
                    { this.state.selectedTab === "notes" && (
                        <DragDrop 
                            listType="notes"
                            listData={notes}
                            addItem={(...args) => backend.addNote(checklistVersionId, ...args)}
                            reorderItems={(...args) => backend.reorderNotes(this.backendRef, checklistVersionId, ...args)}
                            deleteItem={(...args) => backend.deleteNote(checklistVersionId, ...args)}
                            updateItem={(...args) => backend.updateNoteText(checklistVersionId, ...args)}
                        />
                    )}
                    { this.state.selectedTab === "config" && (
                        <ConfigPane
                            checklistVersionId={checklistVersionId}
                            currentTemplate={currentTemplate}
                            possibleTemplates={possibleTemplates}
                            templateVariables={templateVariables}
                            overriddenVariables={overriddenVariables}
                            updateTemplate={(...args) => backend.updateTemplate(checklistVersionId, ...args)}
                            setOverride={(...args) => backend.setVariableOverride(checklistVersionId, ...args)}
                            clearOverride={(...args) => backend.clearVariableOverride(checklistVersionId, ...args)}
                        />
                    )}
                    { this.state.selectedTab === "preview" && (
                        <>
                            <a className="button" rel="noreferrer" href={pdfDownload} target="_blank">Download PDF</a>
                            <a className="button" rel="noreferrer" href={excelDownload} target="_blank">Download Excel</a>
                            <PreviewPane checklistVersionId={checklistVersionId} />
                            
                        </>
                    )}
                    { this.state.selectedTab === "publish" && (
                        isEditable ? (
                            <PublishPane 
                                numberOfEditorsApartFromYou={numberOfEditorsApartFromYou}

                                publishChecklistVersion={() => {
                                    backend.publishChecklistVersion(checklistVersionId);
                                    this.props.history.push("/" + organisationSlug + "/master/list");
                                }}
                            />
                        ) : (
                            <Text>Not editable</Text>
                        )
                    )}
                </div>

            </div>
        );
    }  
}

ChecklistEdit.contextType = BackendContext;
export default ChecklistEdit;
