export function getEndpoint() {
    let backendEndpoint = "https://checklists-backend.gvcs.fbinhouse.se";
    if (/^localhost:/.test(window.location.host)) {
        // Localhost
        backendEndpoint = "https://stage-checklists-backend.gvcs.fbinhouse.se";

    } else if (window.location.host === "hector.fbinhouse.se" || window.location.host === "hector.c1.fbinhouse.se") {
        // Hector
        backendEndpoint = "https://stage-checklists-backend.gvcs.fbinhouse.se";

    } else if (window.location.host === "stage-usedcaradmin.volvocars.biz") {
        // Staging
        backendEndpoint = "https://stage-checklists-backend.gvcs.fbinhouse.se";

    }

    // This is to enable local testing by entering (in the browser console): window.localStorage.setItem("backendEndpoint", "http://localhost:8081");
    const lsBackend = window.localStorage.getItem("backendEndpoint");
    if (lsBackend) {
        backendEndpoint = lsBackend;
    }

    console.log("Using backend endpoint", backendEndpoint);
    return backendEndpoint;
}