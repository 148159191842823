import React from 'react';
import './styles.scss';
import { BackendContext } from '../BackendContext';
import UserRegistrations from '../UserRegistrations';
import { PreviewPane } from '../PreviewPane';
import { Text, TabNav, TabNavItem } from 'vcc-ui';
import shajs from 'sha.js';
import { TranslationList } from '../TranslationList';
import { AuthorPane } from '../AuthorPane';
import { Link } from 'react-router-dom';
import leftArrow from '../../assets/left-arrow.svg';
import { DragDrop } from '../DragDrop';
import { langs } from '../../lib/flags';
import LoginRequired from '../LoginRequired';
import PermissionRequired from '../PermissionRequired';
import { getEndpoint } from '../../lib/endpoint';

const backendEndpoint = getEndpoint();

class LocalChecklistEdit extends React.Component {

    constructor(props) {
        super(props);

        const tabId = this.getTabIdParam();

        this.state = {
            connectionStatus: null,
            localChecklist: null,
            selectedTab: tabId,
        };
    }

    getCurrentlyLoggedInUserFromConnectionStatus() {
        if (this.state.connectionStatus) {
            if (this.state.connectionStatus.meta) {
                if (this.state.connectionStatus.meta.user) {
                    const idp = this.state.connectionStatus.meta.user.idp;
                    if (idp !== "none") {
                        return this.state.connectionStatus.meta.user;
                    }
                }
            }
        }
        return null;
    }

    getOrganisationSlug() {
        let slug = null;
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.org) {
                    slug = this.props.match.params.org;
                }
            }
        }
        return slug;
    }

    getChecklistId() {
        // Use this prop
        let clId = this.props.checklistId;

        // Or override using the path match param (if it exists)
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.checklistId) {
                    clId = this.props.match.params.checklistId;
                }
            }
        }
        return clId;
    }

    getLanguageCode() {
        // Use this prop
        let languageCode = this.props.languageCode;

        // Or override using the path match param (if it exists)
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.languageCode) {
                    languageCode = this.props.match.params.languageCode;
                }
            }
        }
        return languageCode;
    }

    getLanguageName() {
        const code = this.getLanguageCode();
        if (code in langs) {
            return langs[code].name;
        }
    }

    getTabIdParam() {
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.tabId) {
                    return this.props.match.params.tabId;
                }
            }
        }
        return "checks"; // Default, if not set
    }

    componentDidMount() {
        const checklistId = this.getChecklistId();
        const languageCode = this.getLanguageCode();

        // Tell the backend about this component starting up
        this.backendRef = this.context.backend.register("localChecklist", {
            checklistId,
            languageCode,
        }, (reducerCallback) => {
            // The reducerCallback takes in the old state and returns the new state
            this.setState((oldState) => {
                const oldCLV = oldState.localChecklist;
                const newCLV = reducerCallback(oldCLV);
                if (newCLV) {
                    // Special case where the reducer returns null/undefined, don't do anything
                    return {
                        ...oldState,
                        localChecklist: newCLV,
                    }
                }
            });
        }, (newConnectionStatus) => {
            this.setState((oldState) => {
                return {
                    ...oldState,
                    connectionStatus: newConnectionStatus,
                }
            });
        });

        this.unlisten = this.props.history.listen((record, type) => {
            if (type === "POP") {
                const result = /\/([^/]+)$/.exec(record.pathname);
                if (result) {
                    let tabId = result[1];
                    if (['checks', 'notes', 'config', 'preview', 'author'].indexOf(tabId) === -1) {
                        tabId = 'checks';
                    }
                    this.changeTab(tabId, false);
                }
            }
        });
    }
  
    componentWillUnmount() {
        this.context.backend.unRegisterData(this.backendRef);
        this.unlisten();
    }

    hash(text) {
        return shajs('sha256').update(text).digest('hex');
    }

    getOrderedRowItems = () => {
        
        // Checklist version object must exist in state
        if (this.state.localChecklist === null || (typeof this.state.localChecklist.checklistVersion) === "undefined" || !this.state.localChecklist.checklistVersion.rowItemsOrder) {
            return [];
        }

        // Order array must be unique
        const rowItemsOrder = this.state.localChecklist.checklistVersion.rowItemsOrder.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        const result = [];
        // First add items in the order list
        rowItemsOrder.forEach(itemId => {
            if (itemId in this.state.localChecklist.checklistVersion.rowItems) {
                const item = this.state.localChecklist.checklistVersion.rowItems[itemId];
                if (item.type === "category" || item.type === "subCategory" || item.type === "check" || item.type === "inputBox") {
                    if (item.text !== "") {
                        result.push({ ...item, id: itemId });
                    }
                }
            }
        });

        // Should we also append all the items which do not appear in the order array here? No (for now)

        // This merges in the existing translations
        const translationItems = this.state.localChecklist.checklistTranslation.rowItems;
        this.hydrateTranslations(result, translationItems);

        // console.log("RESULTS", result);

        return result;
    }

    getOrderedLocalRowItems = () => {
        // Get this from the localRowItems and localRowItemsOrder

        // Checklist object must exist in state
        if (this.state.localChecklist === null || !this.state.localChecklist.checklistTranslation || !this.state.localChecklist.checklistTranslation.localRowItemsOrder) {
            return [];
        }

        // Order array must be unique
        const rowItemsOrder = this.state.localChecklist.checklistTranslation.localRowItemsOrder.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        const result = [];
        // First add items in the order list
        rowItemsOrder.forEach(itemId => {
            if (itemId in this.state.localChecklist.checklistTranslation.localRowItems) {
                result.push({ ...this.state.localChecklist.checklistTranslation.localRowItems[itemId], id: itemId });
            }
        });

        // Should we also append all the items which do not appear in the order array here? No (for now)

        return result;
        
    }

    hydrateTranslations(result, translationItems) {
        // console.log("translationItems", translationItems);
        result.forEach(item => {
            // Hash the master text here
            const hashedText = this.hash(item.text);
                        
            // Find an appropriate translation record for this combination
            let foundTranslation = null;
            if (item.id in translationItems) {
                const transItem = translationItems[item.id];
                if ('translations' in transItem && typeof transItem.translations === "object" && transItem.translations !== null) {
                    if (hashedText in transItem.translations) {
                        foundTranslation = transItem.translations[hashedText];
                    }
                }
            }
            item.hashedText = hashedText;
            item.translation = foundTranslation;
        });
    }

    getOrderedNotes = () => {
        // Checklist object must exist in state
        if (this.state.localChecklist === null || (typeof this.state.localChecklist.checklistVersion) === "undefined" || !this.state.localChecklist.checklistVersion.notesOrder) {
            return [];
        }

        // Order array must be unique
        const notesOrder = this.state.localChecklist.checklistVersion.notesOrder.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        const result = [];
        // First add items in the order list
        notesOrder.forEach(itemId => {
            if (itemId in this.state.localChecklist.checklistVersion.notes) {
                result.push({ ...this.state.localChecklist.checklistVersion.notes[itemId], id: itemId });
            }
        });

        // Should we also append all the items which do not appear in the order array here? No (for now)

        // This merges in the existing translations
        const translationItems = this.state.localChecklist.checklistTranslation.notes;
        this.hydrateTranslations(result, translationItems);

        return result;
    }

    getChecklistName = () => {
        // Checklist object must exist in state
        if (this.state.localChecklist === null || !this.state.localChecklist.checklist) {
            return "";
        } else {
            return this.state.localChecklist.checklist.name;
        }
    }

    
    getCurrentVersionNumber = () => {
        if (this.state.localChecklist === null || (typeof this.state.localChecklist.checklistVersion) === "undefined" || !this.state.localChecklist.checklistVersion.versionNumber) {
            return null;
        } else {
            return this.state.localChecklist.checklistVersion.versionNumber;
        }
    }

    getCurrentChecklistVersionId = () => {
        if (this.state.localChecklist === null || (typeof this.state.localChecklist.checklistVersion) === "undefined") {
            return null;
        } else {
            return this.state.localChecklist.checklistVersion._id;
        }
    }

    getMasterVariables = () => {
        // Merge the templateVariables with the overriddenVariables
        if (this.state.localChecklist === null || (typeof this.state.localChecklist.checklistVersion) === "undefined" || !this.state.localChecklist.checklistVersion.templateVariables || !this.state.localChecklist.checklistVersion.overriddenVariables) {
            return [];
        } else {
            const templateVariables = this.state.localChecklist.checklistVersion.templateVariables;
            const overriddenVariables = this.state.localChecklist.checklistVersion.overriddenVariables;

            // console.log("templateVariables", templateVariables);
            // console.log("overriddenVariables", overriddenVariables);

            const result = [];
            templateVariables.forEach((context) => {
                const contextName = context.context;
                Object.keys(context.variables).forEach(transId => {
                    let variableText = context.variables[transId];

                    // Is there an override in the checklist version
                    const transKey = contextName + "_" + transId;
                    if (transKey in overriddenVariables) {
                        variableText = overriddenVariables[transKey];
                    }

                    result.push({
                        id: transKey,
                        text: variableText,
                    });
                });
            });

            // This merges in the existing translations
            const translationItems = this.state.localChecklist.checklistTranslation.variables;
            this.hydrateTranslations(result, translationItems);

            return result;
        }
    }

    getMyConnectionId() {
        if (this.state.connectionStatus === null || !this.state.connectionStatus.meta || this.state.connectionStatus.meta === null) {
            return null;
        } else {
            return this.state.connectionStatus.meta.connectionId;
        }
    }
    getUserOnThisPage() {
        if (this.state.localChecklist === null || (typeof this.state.localChecklist.checklist) === "undefined" || !this.state.localChecklist._regs) {
            return {};
        } else {
            // Find the sockets that have at least one view matching this page
            const list = {};
            Object.keys(this.state.localChecklist._regs).map(socketId => {
                const reg = this.state.localChecklist._regs[socketId];
                let hasAppropriateView = false;
                Object.keys(reg.views).forEach(viewId => {
                    const view = reg.views[viewId];
                    if (view.listenType === "localChecklist" && view.listenParams.checklistId === this.state.localChecklist.checklist._id && view.listenParams.languageCode === this.state.localChecklist.checklistTranslation.languageCode) {
                        hasAppropriateView = true;
                    }
                })
                if (hasAppropriateView) {
                    list[socketId] = reg;
                }
                return null;
            });
            return list;
        }
    }

    changeTab(tabName, pushState = true) {
        this.setState((oldState) => {
            return {
                ...oldState,
                selectedTab: tabName,
            }
        });
        if (pushState) {
            // Push history
            const checklistId = this.getChecklistId();
            const languageCode = this.getLanguageCode();
            const organisationSlug = this.getOrganisationSlug();
            this.props.history.push("/" + organisationSlug + "/local/" + languageCode + "/" + checklistId + "/" + tabName);
        }
    }

    downloadUrl(type, checklistId, languageCode) {
        return backendEndpoint + "/generate/" + type + "/local/" + languageCode + "/" + checklistId + "?ts=" + (new Date()).getTime();
    }

    render() {
        const checklistId = this.getChecklistId();
        const languageCode = this.getLanguageCode();
        const languageName = this.getLanguageName();

        const rowItems = this.getOrderedRowItems();
        const localRowItems = this.getOrderedLocalRowItems();
        const notes = this.getOrderedNotes();
        const masterVariables = this.getMasterVariables();

        const checklistName = this.getChecklistName();

        const backend = this.context.backend;

        const usersOnThisPage = this.getUserOnThisPage();
        const myConnectionId = this.getMyConnectionId();
        
        const checklistVersionId = this.getCurrentChecklistVersionId();
        const versionNumber = this.getCurrentVersionNumber();

        let showWarningFor = "this checklist.";

        const checksToTranslate = rowItems.filter(item => item.translation === null).length;
        const notesToTranslate = notes.filter(item => item.translation === null).length;
        const variablesToTranslate = masterVariables.filter(item => item.translation === null).length;

        let numberOfEditorsApartFromYou = 0;
        Object.keys(usersOnThisPage).forEach(connectionId => {
            if (myConnectionId !== connectionId) {
                numberOfEditorsApartFromYou++;
            }
        });
        
        const organisationSlug = this.getOrganisationSlug();

        const organisation = this.props.organisation;
        const user = this.getCurrentlyLoggedInUserFromConnectionStatus();

        // Note: Organisation should always be set on a page with org slug in the url
        if (organisation === null) {
            return null;
        }
        
        if (user === null) {
            return (
                <div className="users">
                    <LoginRequired history={this.props.history} />
                </div>
            )
        } else {
            // We are logged in, check organisation permissions
            if (!(organisation._id in user.permissions.organisations) || !user.permissions.organisations[organisation._id].localTransLangs || user.permissions.organisations[organisation._id].localTransLangs.indexOf(languageCode) === -1) {
                return (
                    <div className="users">
                        <PermissionRequired history={this.props.history} permissionName={organisation.name + ": Local Editor (" + languageName + ")"} />
                    </div>
                )
            }
        }

        const pdfDownload = this.downloadUrl("pdf", checklistId, languageCode);
        const excelDownload = this.downloadUrl("excel", checklistId, languageCode);

        return (
            <div className="checklistEdit">

                <UserRegistrations myConnectionId={myConnectionId} regs={usersOnThisPage} alignRight={true} showWarningWhenSomebodyElseEditing={showWarningFor} />

                <Link to={"/" + organisationSlug + "/local/" + languageCode} className="backBtnWrapper">
                    <div className="backBtn">
                        <img src={leftArrow} alt="back" />
                        <Text as="p" subStyle="emphasis">Back</Text>
                    </div>
                </Link>
                
                <div className="checklistTitle">
                    <Text subStyle="emphasis">Translating Checklist: {checklistName}</Text>
                </div>
                
                <Text style={{marginBottom: '20px'}}>Version: {versionNumber}</Text>

                <div className="navWrapper">
                    <TabNav enableLineTransition textAlign={'left'}>
                        <TabNavItem
                            isActive={this.state.selectedTab === 'checks'}
                            onClick={() => this.changeTab('checks')}
                        >
                            Checks {checksToTranslate > 0 ? (
                                <span className="todo">&nbsp;({checksToTranslate})</span>
                            ) : (
                                    <span className="todo none">&nbsp;(0)</span>
                                )}
                        </TabNavItem>

                        <TabNavItem
                            isActive={this.state.selectedTab === 'localChecks'}
                            onClick={() => this.changeTab('localChecks')}
                        >
                            Local Only ({languageName})
                        </TabNavItem>

                        <TabNavItem
                            isActive={this.state.selectedTab === 'notes'}
                            onClick={() => this.changeTab('notes')}
                        >
                            Notes {notesToTranslate > 0 ? (
                                <span className="todo">&nbsp;({notesToTranslate})</span>
                            ) : (
                                    <span className="todo none">&nbsp;(0)</span>
                                )}
                        </TabNavItem>

                        <TabNavItem
                            isActive={this.state.selectedTab === 'config'}
                            onClick={() => this.changeTab('config')}
                        >
                            Config {variablesToTranslate > 0 ? (
                                <span className="todo">&nbsp;({variablesToTranslate})</span>
                            ) : (
                                    <span className="todo none">&nbsp;(0)</span>
                                )}
                        </TabNavItem>

                        <TabNavItem
                            isActive={this.state.selectedTab === 'preview'}
                            onClick={() => this.changeTab('preview')}
                        >
                            Preview
                        </TabNavItem>

                            <TabNavItem
                                isActive={this.state.selectedTab === 'author'}
                                onClick={() => this.changeTab('author')}
                            >
                                Author
                        </TabNavItem>
                    </TabNav>
                </div>


                <div className="tabPane">
                    { this.state.selectedTab === "checks" && (
                        <TranslationList
                            languageCode={languageCode}
                            listData={rowItems}
                            setEnglishTranslation={(...args) => backend.setEnglishTranslation(checklistId, languageCode, "rowItems", ...args)}
                            doGoogleTranslate={(...args) => backend.doGoogleTranslate(checklistId, languageCode, "rowItems", ...args)}
                            setTranslation={(...args) => backend.setTranslation(checklistId, languageCode, "rowItems", ...args)}
                            clearTranslation={(...args) => backend.clearTranslation(checklistId, languageCode, "rowItems", ...args)}
                        />
                    )}
                    { this.state.selectedTab === "localChecks" && (
                        <DragDrop 
                            listType="rowItems"
                            listData={localRowItems}
                            addItem={(...args) => backend.addLocalRowItem(checklistId, languageCode, ...args)}
                            reorderItems={(...args) => backend.reorderLocalRowItems(this.backendRef, checklistId, languageCode, ...args)}
                            deleteItem={(...args) => backend.deleteLocalRowItem(checklistId, languageCode, ...args)}
                            updateItem={(...args) => backend.updateLocalRowItem(checklistId, languageCode, ...args)}
                        />
                    )}
                    { this.state.selectedTab === "notes" && (
                        <TranslationList
                            languageCode={languageCode}
                            listData={notes}
                            setEnglishTranslation={(...args) => backend.setEnglishTranslation(checklistId, languageCode, "notes", ...args)}
                            doGoogleTranslate={(...args) => backend.doGoogleTranslate(checklistId, languageCode, "notes", ...args)}
                            setTranslation={(...args) => backend.setTranslation(checklistId, languageCode, "notes", ...args)}
                            clearTranslation={(...args) => backend.clearTranslation(checklistId, languageCode, "notes", ...args)}
                        />
                    )}
                    { this.state.selectedTab === "config" && (
                        <TranslationList
                            languageCode={languageCode}
                            listData={masterVariables}
                            setEnglishTranslation={(...args) => backend.setEnglishTranslation(checklistId, languageCode, "variables", ...args)}
                            doGoogleTranslate={(...args) => backend.doGoogleTranslate(checklistId, languageCode, "variables", ...args)}
                            setTranslation={(...args) => backend.setTranslation(checklistId, languageCode, "variables", ...args)}
                            clearTranslation={(...args) => backend.clearTranslation(checklistId, languageCode, "variables", ...args)}
                        />
                    )}
                    { this.state.selectedTab === "preview" && (
                        <>
                            <a className="button" rel="noreferrer" href={pdfDownload} target="_blank">Download PDF</a>
                            <a className="button" rel="noreferrer" href={excelDownload} target="_blank">Download Excel</a>
                            <PreviewPane checklistId={checklistId} languageCode={languageCode} />
                        </>
                    )}
                    { this.state.selectedTab === "author" && (
                        <AuthorPane 
                            numberOfEditorsApartFromYou={numberOfEditorsApartFromYou}
                            checksToTranslate={checksToTranslate}
                            notesToTranslate={notesToTranslate}
                            variablesToTranslate={variablesToTranslate}

                            authorChecklist={() => {
                                backend.authorChecklist(checklistId, languageCode, checklistVersionId);
                                this.props.history.push("/" + organisationSlug + "/local/" + languageCode);
                            }}
                        />
                    )}
                </div>

            </div>
        );
    }  
}

LocalChecklistEdit.contextType = BackendContext;
export default LocalChecklistEdit;
