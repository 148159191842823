import React from 'react';
import { Text } from 'vcc-ui';
import './styles.scss';

export default function FrontPage({match, clearLoginToken, organisation}) {

    // After go live, swap this around
    const isStaging = window.location.hostname === "stage-usedcaradmin.volvocars.biz";
    // const isStaging = window.location.hostname === "localhost";

    return (
        <div className="frontPage">
            <Text as="h3" variant="cook" style={{textAlign: 'center', paddingTop: '50px'}}>
                {organisation && organisation.name}
            </Text>

            {isStaging && (
                <>
                    <p className="stagingMessage">
                        This is the staging environment which uses a test database.
                        <br/>
                        It contains the starting versions of checklists from March 2021.
                        <br/>
                        Please feel free to mess around and test functionality.
                        <br />
                        If you would like to make live updates to checklists, please visit the <a href="https://usedcaradmin.volvocars.biz">production environment</a>
                    </p>
                    <br />
                    <br />
                </>
            )}

            <p className="stagingMessage">
                Please use the menu items at the top of the page.
            </p>
        </div>
    );

}
