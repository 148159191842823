import React, { useReducer, useEffect, useCallback, useState } from 'react';
import { Text, Button } from 'vcc-ui';
import EdiText from 'react-editext';
import './styles.scss';

const TranslationList = ({ listData, setEnglishTranslation, doGoogleTranslate, setTranslation, clearTranslation, languageCode }) => {

    const selectedCellReducer = useCallback((state, action) => {
        // console.log("Key event", action.type);
        if (action.type === "stopEditing") {
            return {
                ...state,
                editing: false,
            }
        } else if (action.type === "editText") {
            return {
                ...state,
                row: action.row,
                editing: true,
            }
        }
        else if (action.type === "selectCell") {
            return {
                ...state,
                row: action.row,
                editing: false
            }
        } else if (action.keyCode === 38) {
            // Up
            // You can't move the cell if you're editing
            if (!state.editing) {
                if (state.row > 0) {
                    return {
                        ...state,
                        row: state.row - 1,
                    }
                }
            }
        } else if (action.keyCode === 40) {
            // Down
            if (!state.editing) {
                if (state.row < (listData.length - 1)) {
                    return {
                        ...state,
                        row: state.row + 1,
                    }
                }
            }
        } else if (action.keyCode === 37) {
            // Left
            // Only 1 col
        } else if (action.keyCode === 39) {
            // Right
            // Only 1 col
        } else if (action.keyCode === 13) {
            // Enter
            // If we are editing already, this counts as save + move down 1 cell
            let newRow = state.row;

            if (state.editing) {
                // Can we move down?
                if (state.row < (listData.length - 1)) {
                    newRow = state.row + 1;
                } else {
                    // Don't move down
                }
                return {
                    ...state,
                    row: newRow,
                    editing: false,
                }
            } else {
                return {
                    ...state,
                    editing: true,
                }
            }

        } else if (action.keyCode === 27) {
            // Escape
            // This should cancel editing but stay on the same cell
            return {
                ...state,
                editing: false,
            }
        }
        return state;
    }, [listData]);

    const [selectedCell, dispatchKeyEvent] = useReducer(selectedCellReducer, {
        row: 0,
        // col: 0, // Note: There is only 1 column in this list component
        editing: false, // Can be true/false
    })

    useEffect(() => {
        const selectedCellElement = document.querySelector('.selected');
        if (selectedCellElement) {
            const cellOffset = selectedCellElement.offsetTop;

            // console.log("Scrolling to cell at " + cellOffset);
            const cellHeight = 50;
            const screenMinY = window.scrollY;
            const screenMaxY = screenMinY + window.innerHeight;

            // The area considered too close to the edge of the top and bottom of the screen.
            // This can be a maximum of half of the window.innerHeight
            const viewportBorder = Math.min(100, Math.round(window.innerHeight / 2)); 
            
            if (cellOffset < (screenMinY + viewportBorder)) {
                // Cell is above viewport, scroll up
                // Move the cell to within the sweetspot of the viewport
                const newY = Math.max(0, cellOffset - viewportBorder);
                window.scroll(0, newY);

            } else if ((cellOffset + cellHeight) > (screenMaxY - viewportBorder)) {
                // Cell is below viewport, scroll down
                // Move the cell to within the sweetspot of the viewport
                const newY = Math.max(0, cellOffset + cellHeight - window.innerHeight + viewportBorder);
                window.scroll(0, newY);

            } else {
                // No need to scroll

            }
        }
    }, [selectedCell]);


    const stopEditing = () => {
        setTimeout(() => {
            dispatchKeyEvent({ type: "stopEditing"});
        }, 10);
    }
    const editText = (index) => {
        dispatchKeyEvent({ type: "stopEditing" });
        dispatchKeyEvent({ type: "editText", row: index });
    }

    useEffect(() => {
        const keyDownHandler = (e) => {
            // console.log(e.keyCode);
            if (e.keyCode === 13 || e.keyCode === 27 || (e.keyCode >= 37 && e.keyCode <= 40)) {
                dispatchKeyEvent({ keyCode: e.keyCode});
                // e.preventDefault(); // We can't prevent default here since it swallows cursor key movements within the text box when editing
            }
            
        }
        window.addEventListener('keydown', keyDownHandler);
        // Remove on cleanup
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        }
    }, [selectedCell]); // Empty array ensures that effect is only run on mount and unmount

    const sleep = async (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms);
        })
    }
    const setEnglishTranslationForRemainingItems = async () => {
        setInProgress(true);
        try {
            for(let index=0; index<listData.length; index++) {
                const item = listData[index];
                if (item.translation) {
                    // Exists
                } else {
                    await setEnglishTranslation(item.id, item.hashedText, item.text);
                    await sleep(100);
                }
            }
            setInProgress(false);
        } catch(e) {
            console.warn(e);
            setInProgress(false);
        }
    }
    const doGoogleTranslationForRemainingItems = async () => {
        setInProgress(true);
        try {
            for(let index=0; index<listData.length; index++) {
                const item = listData[index];
                if (item.translation) {
                    // Exists
                } else {
                    await doGoogleTranslate(item.id, item.hashedText, item.text);
                    await sleep(100);
                }
            }
            setInProgress(false);
        } catch(e) {
            console.warn(e);
            setInProgress(false);
        }
    }

    const [inProgress, setInProgress] = useState(false);

    let numberRemaining = 0;
    for(let index=0; index<listData.length; index++) {
        const item = listData[index];
        if (item.translation) {
            // Exists
        } else {
            numberRemaining++;
        }
    }

    const isEnglish = languageCode.substring(0,2).toLowerCase() === "en";

    return (
        <div className="translationList">
            {numberRemaining > 0 && (
                <div className="usefulButtons">
                    {inProgress ? (
                        <span>Please wait... {numberRemaining} remaining items</span>
                    ) : (
                        <>
                            <Button onClick={() => setEnglishTranslationForRemainingItems()}>Use English for the {numberRemaining} remaining items</Button>
                            {!isEnglish && (<Button onClick={() => doGoogleTranslationForRemainingItems()}>Google Translate the remaining {numberRemaining} items</Button>)}
                        </>
                    )}
                </div>
            )}
            <div className="innerWrapper">
                <div className="dataRow">
                    <div className="dataCell dataCellHeaderMaster">
                        <Text as="p" subStyle="emphasis">Master Text (EN)</Text>
                    </div>
                    <div className="dataCell dataCellHeaderLocal">
                        <Text as="p" subStyle="emphasis">Local text ({languageCode})</Text>
                    </div>
                </div>
                
                {listData.map((item, index) => (
                    <div className={item.translation ? "dataRow" : "dataRow missing"} key={item.id}>
                        <div className="dataCell dataCellMaster">
                            <Text>{item.text}</Text>
                        </div>
                        <div className={selectedCell.row === index ? "selected dataCell dataCellLocal" : "dataCell dataCellLocal"}>
                            {(selectedCell.row === index && selectedCell.editing) ? (
                                <EdiText
                                    type='text' // Note: submitOnEnter does not work if this is textarea so the enter key will stop editing without saving.
                                    onSave={(val) => { setTranslation(item.id, item.hashedText, item.text, val); stopEditing(); }}
                                    onCancel={() => { stopEditing(); }}
                                    
                                    cancelOnEscape
                                    submitOnEnter                  

                                    editing={selectedCell.row === index && selectedCell.editing}
                                    value={item.translation !== null ? item.translation.localText : ""}
                                    saveButtonClassName="custom-save-button"
                                    cancelButtonClassName="custom-cancel-button"
                                    inputProps={{
                                        // className: 'textarea',
                                        style: {
                                            outline: 'none'
                                        },
                                        rows: 1
                                    }}
                                    viewProps={{
                                        className: 'valueText'
                                    }}
                                />
                            ) : (
                                item.translation ? (
                                    <>
                                        <div onClick={() => editText(index)}>{item.translation.localText}</div>
                                        <div className="buttonWrapper edit">
                                            <Button style={{ float: "right" }} onClick={() => clearTranslation(item.id, item.hashedText)}>Clear</Button>
                                        </div>
                                        
                                    </>
                                ) : (
                                    <div className="buttonContainer">
                                        <div className="buttonWrapper">
                                            <Button onClick={() => setEnglishTranslation(item.id, item.hashedText, item.text)}>Use English</Button>
                                        </div>
                                        <div className="buttonWrapper">
                                            <Button onClick={() => doGoogleTranslate(item.id, item.hashedText, item.text)}>Google Translate</Button>
                                        </div>
                                        <div className="buttonWrapper">
                                            <Button onClick={() => editText(index)}>Manual Text</Button>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {numberRemaining > 0 && (
                <div className="usefulButtons">
                    <br />
                    {inProgress ? (
                        <span>Please wait... {numberRemaining} remaining items</span>
                    ) : (
                        <>
                            <Button onClick={() => setEnglishTranslationForRemainingItems()}>Use English for the {numberRemaining} remaining items</Button>
                            {!isEnglish && (<Button onClick={() => doGoogleTranslationForRemainingItems()}>Google Translate the remaining {numberRemaining} items</Button>)}
                        </>
                    )}
                </div>
            )}
        </div>
    );

}

export {
    TranslationList
};
