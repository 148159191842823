import React from 'react';
import { Text } from 'vcc-ui';
import warningIcon from '../../assets/warning.svg';
import './styles.scss';

export default function UserRegistrations({regs, myConnectionId, alignRight, showWarningWhenSomebodyElseEditing}) {

    // Sort the registrations here so that YOU are the first one
    // console.log("REGS", regs);

    const listedRegs = [];
    Object.keys(regs).map(socketId => {
        listedRegs.push({
            connectionId: socketId,
            user: regs[socketId].loggedInUser,
            views: regs[socketId].views,
        });
        return null;
    })

    const sortedRegs = listedRegs.sort((a,b) => {
        if (a.connectionId === myConnectionId) {
            return -1;
        } else if (b.connectionId === myConnectionId) {
            return 1;
        } else {
            return 0;
        }
    });

    let numberOfEditorsApartFromYou = 0;
    sortedRegs.forEach(reg => {
        if (myConnectionId !== reg.connectionId) {
            numberOfEditorsApartFromYou++;
        }
    });

    // console.log("sortedRegs", sortedRegs);

    let className = "userRegistrations";
    if (alignRight) {
        className += " alignRight";
    }
    if (sortedRegs.length === 0) {
        className += " hide";
    }

    return (
        <div className={className}>
            { typeof showWarningWhenSomebodyElseEditing === "string" && numberOfEditorsApartFromYou > 0 && (
                <div className="warning">
                    <div className="warningWrapper">
                        <img src={warningIcon} alt="warning" />
                        <Text>
                            <span>Warning</span>: It looks like somebody else is currently editing {showWarningWhenSomebodyElseEditing}
                        </Text>
                    </div>
                    
                </div>
            )}
            
            <div className="editingSymbols">
                <Text 
                    as="p" 
                    subStyle="emphasis" 
                    style={{textAlign: 'center'}}
                >
                    Currently editing
                </Text>

                {sortedRegs.map(reg => (
                    <div
                        key={reg.connectionId}
                        className={"userReg " + (myConnectionId === reg.connectionId ? "you" : "")}
                        title={reg.user.firstName + " " + reg.user.lastName + " " + reg.user.email}
                    >
                        <div className={myConnectionId === reg.connectionId ? "initials current" : "initials"}>
                            {reg.user.initials}
                        </div>
                        {myConnectionId === reg.connectionId && (
                            <div className="you">YOU</div>
                        )}
                    </div>
                ))}
            </div>

        </div>
    );

}
