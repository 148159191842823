import React from 'react';
import './styles.scss';

class DisconnectedBar extends React.Component {


    componentDidMount() {
        
    }
  
    componentWillUnmount() {
        
    }

    doLogin() {
        this.props.clearLoginToken();
        
        const pushUrl = "/login?redirectUrl=" + encodeURIComponent(window.location.pathname);
        this.props.history.push(pushUrl);
    }

    render() {
        const connection = this.props.connection;

        if (connection === null) {
            return null;
        }
        if (connection.status === "LOGIN_FAILED") {
            return (
                <>
                    <div className="disconnectedBar">
                        Login Failed: {connection.meta.reason}  <button onClick={() => this.doLogin()}>Try again</button>
                    </div>
                </>
            )
        } else if (connection.status !== "CONNECTED" && connection.status !== "SHUTDOWN" && connection.status !== "WAITING") {
            return (
                <>
                    <div className="disconnectedBar">
                        Disconnected.  Reconnecting...
                    </div>
                </>
            )
        } else {
            return null;
        }
        
    }  
}

export default DisconnectedBar;
