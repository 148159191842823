import React, { useReducer, useEffect, useCallback, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ListItem } from '../ListItem';
import { Text } from 'vcc-ui';
import addIcon from '../../assets/add.svg'
import './styles.scss';

const DragDrop = ({ listType, listData, addItem, reorderItems, deleteItem, updateItem }) => {

    const [dragDisable, setDragDisable] = useState(false);

    // Note: listType can be 'rowItems' or 'notes' depending on what is being drag/dropped

    const selectedCellReducer = useCallback((state, action) => {
 
        // console.log("DragDrop received key event", action);
        if (action.type === "stopEditing") {
            // There exists a bug where stopEditing will do a full re-render of the ediText component which means the blur event
            // does not get fired properly and the data doesnt get saved.  BUT the state of the ediText does update weirdly.
            // This is now fixed between text and type selections with the mouse, but not when changing rows with the mouse.
            // TODO Fix the case where a user is selecting a new check with the mouse.  It should save the old one.
            return {
                ...state,
                editing: null,
            }
        } else if (action.type === "editText") {
            return {
                ...state,
                row: action.row,
                col: 0,
                editing: 'text',
            }
        }
        else if (action.type === "selectCell") {
            return {
                ...state,
                row: action.row,
                col: 0,
                editing: null
            }
        } else if (action.type === "editType") {
            return {
                ...state,
                row: action.row,
                col: 1,
                editing: 'type',
            }
        } else if (action.type === "moveUpOneRow") {
            // Same as key up
            if (state.row > 0) {
                return {
                    ...state,
                    row: state.row - 1,
                }
            }
        } else if (action.keyCode === 38) {
            // Up
            // You can't move the cell if you're editing
            if (state.editing === null) {
                if (state.row > 0) {
                    return {
                        ...state,
                        row: state.row - 1,
                    }
                }
            }
        } else if (action.keyCode === 40) {
            // Down
            if (state.editing === null) {
                if (state.row < (listData.length - 1)) {
                    return {
                        ...state,
                        row: state.row + 1,
                    }
                } else {
                    // Add new item if the last item is not empty OR if last item is comments or tyre table
                    if (listType === "rowItems") {
                        if (listData[listData.length - 1].type === "tyreTable" || listData[listData.length - 1].type === "comments" || listData[listData.length - 1].text !== "") {
                            addItem("", "check");
                            // Then move down to it
                            return {
                                ...state,
                                row: listData.length,
                            }
                        }
                    } else {
                        // E.g. notes
                        if (listData[listData.length - 1].text !== "") {
                            addItem("");
                            // Then move down to it
                            return {
                                ...state,
                                row: listData.length,
                            }
                        }
                    }
                }
            }
        } else if (action.keyCode === 37) {
            // Left
            if (state.editing === null) {
                if (state.col > 0) {
                    return {
                        ...state,
                        col: state.col - 1,
                    }
                }
            }
        } else if (action.keyCode === 39) {
            // Right
            if (state.editing === null) {
                let numOfCols = 1;
                if (listType === "rowItems") {
                    numOfCols = 2;
                }

                if (state.col < (numOfCols - 1)) {
                    return {
                        ...state,
                        col: state.col + 1,
                    }
                }
            }
        } else if (action.keyCode === 13) {
            // Enter
            // If we are editing already, this counts as save + move down 1 cell
            let newRow = state.row;

            if (listType === "rowItems") {
                if (listData[state.row].type === "tyreTable" || listData[state.row].type === "comments") {
                    if (state.col === 0) {
                        if (state.row < (listData.length - 1)) {
                            newRow = state.row + 1;
                        }
                        return {
                            ...state,
                            row: newRow,
                            editing: null,
                        }
                    }
                }
            }

            if (state.editing !== null) {
                // Can we move down?
                if (state.row < (listData.length - 1)) {
                    newRow = state.row + 1;
                } else {
                    // Don't move down
                }
                return {
                    ...state,
                    row: newRow,
                    editing: null,
                }
            } else {
                // Start editing based on column
                let newEditing = state.editing;
                if (state.col === 0) {
                    newEditing = "text";
                } else if (state.col === 1) {
                    newEditing = "type";
                }
                return {
                    ...state,
                    editing: newEditing,
                }
            }

        } else if (action.keyCode === 27) {
            // Escape
            // This should cancel editing but stay on the same cell
            return {
                ...state,
                editing: null,
            }
        }
        return state;
    }, [listType, listData, addItem]);

    const [selectedCell, dispatchKeyEvent] = useReducer(selectedCellReducer, {
        row: 0,
        col: 0,
        editing: null, // Could be "text" or "type"
    })

    useEffect(() => {
        const selectedCellElement = document.querySelector('.selected');
        if (selectedCellElement) {
            const cellOffset = selectedCellElement.offsetTop;

            // console.log("Scrolling to cell at " + cellOffset);
            const cellHeight = 50;
            const screenMinY = window.scrollY;
            const screenMaxY = screenMinY + window.innerHeight;

            // The area considered too close to the edge of the top and bottom of the screen.
            // This can be a maximum of half of the window.innerHeight
            const viewportBorder = Math.min(100, Math.round(window.innerHeight / 2)); 
            
            if (cellOffset < (screenMinY + viewportBorder)) {
                // Cell is above viewport, scroll up
                // Move the cell to within the sweetspot of the viewport
                const newY = Math.max(0, cellOffset - viewportBorder);
                window.scroll(0, newY);

            } else if ((cellOffset + cellHeight) > (screenMaxY - viewportBorder)) {
                // Cell is below viewport, scroll down
                // Move the cell to within the sweetspot of the viewport
                const newY = Math.max(0, cellOffset + cellHeight - window.innerHeight + viewportBorder);
                window.scroll(0, newY);

            } else {
                // No need to scroll

            }
        }
    }, [selectedCell]);


    const stopEditing = () => {
        dispatchKeyEvent({ type: "stopEditing"});
    }
    const editText = (index, val) => {

        if(val.editable) {
            stopEditing();
            // dispatchKeyEvent({ type: "stopEditing" });
            dispatchKeyEvent({ type: "editText", row: index });            
        } else {
            stopEditing();
            // dispatchKeyEvent({ type: "stopEditing" });
            dispatchKeyEvent({ type: "selectCell", row: index });
        }

    }
    const editType = (index) => {
        stopEditing();
        //dispatchKeyEvent({ type: "stopEditing" });
        dispatchKeyEvent({ type: "editType", row: index });
    }
    const moveUpOneRow = () => {
        dispatchKeyEvent({ type: "moveUpOneRow" }); // Same as pressing up key
    }

    useEffect(() => {
        const keyDownHandler = (e) => {
            // console.log(e.keyCode);
            if (e.keyCode === 13 || e.keyCode === 27 || (e.keyCode >= 37 && e.keyCode <= 40)) {
                dispatchKeyEvent({ keyCode: e.keyCode});
                // e.preventDefault(); // We can't prevent default here since it swallows cursor key movements within the text box when editing
            }
            
        }
        window.addEventListener('keydown', keyDownHandler);
        // Remove on cleanup
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        }
    }, [selectedCell]); // Empty array ensures that effect is only run on mount and unmount

    const onDragEnd = (result) => {
        const { destination, draggableId } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        // console.log("Moving id " + draggableId + " to destination index " + destination.index);
        reorderItems(draggableId, destination.index);
    }

    const insertRow = (index) => {
        if (listType === "rowItems") {
            addItem("", 'check', index);
        } else {
            addItem("", index);
        }
    }

    const disableDrag = (val) => {
        setDragDisable(val)
    }

    return (
        <div className="listData">
            <DragDropContext onDragEnd={onDragEnd}>
                                    
                <Droppable droppableId="main">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className="droppable">
                            <div className="innerWrapper">
                                    <div className="dataRow">
                                    <div className={listType === "notes" ? "dataCell dataCellHeaderProduct notes" : "dataCell dataCellHeaderProduct"}>
                                            <Text as="p" subStyle="emphasis">Text</Text>
                                        </div>
                                        {listType === "notes" ? <div style={{ width: "50px", borderTop: "1px solid #e8ecee", borderRight: "1px solid #e8ecee"}}></div> : null}
                                        {listType === "rowItems" && (
                                            <div className="dataCell dataCellHeaderType">
                                                <Text as="p" subStyle="emphasis">Category</Text>
                                            </div>
                                        )}
                                        
                                    </div>
                                   
                                    {listData.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={dragDisable} >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="dataRow"
                                                >

                                                    <ListItem
                                                        listType={listType}

                                                        isRowSelected={selectedCell.row === index}
                                                        selectedCellCol={selectedCell.col}
                                                        triggerEditText={(val) => editText(index, val)}
                                                        triggerEditType={() => editType(index)}
                                                        stopEditing={() => stopEditing()}
                                                        editingText={selectedCell.row === index && selectedCell.editing === "text"}
                                                        editingType={selectedCell.row === index && selectedCell.editing === "type"}

                                                        item={item}
                                                        removeData={() => {
                                                            deleteItem(item.id);
                                                            moveUpOneRow();
                                                        }}
                                                        updateText={(val) => {
                                                            // console.log("Update text...", item.id, val);

                                                            if (listType === "rowItems") {
                                                                return updateItem(item.id, 'text', val)
                                                            } else {
                                                                // E.g. notes
                                                                return updateItem(item.id, val);
                                                            }
                                                        }}
                                                        updateType={(val) => updateItem(item.id, 'type', val)}
                                                        insertRow={() => insertRow(index)}
                                                        disableDrag={(val) => disableDrag(val)}
                                                        className={listType === "notes" ? "notesList" : ''}
                                                    />

                                                </div>
                                            )}

                                        </Draggable>

                                    ))}

                                    <div onClick={() => insertRow()} className="addRowButton">
                                        <div className="addWrapper">
                                            <img src={addIcon} alt="add" />
                                            <Text subStyle="emphasis">Add row</Text>
                                        </div>
                                    </div>

                            </div>

                            { provided.placeholder}

                        </div>
                    )}
                </Droppable>
                
            </DragDropContext>

        </div>
    );

}

export {
    DragDrop
};
