import React from 'react';
import { Text } from 'vcc-ui';
import './styles.scss';
import { BackendContext } from '../BackendContext';
import LanguageCodes from '../../languageCodes.json';
import { Link } from 'react-router-dom';
import DownloadLatest from '../DownloadLatest';
import { format } from 'date-fns';
import excelIcon from '../../assets/excel.svg';
import pdfIcon from '../../assets/pdf.svg';
import htmlIcon from '../../assets/html.svg';
import leftArrow from '../../assets/left-arrow.svg';

class Downloads extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            connectionStatus: null,
            downloads: null,
        }
    }

    getOrganisationSlug() {
        let slug = null;
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.org) {
                    slug = this.props.match.params.org;
                }
            }
        }
        return slug;
    }

    getCurrentChecklistFromUrlParams(checklists) {
        if (this.props.match && this.props.match.params && this.props.match.params.shortCode && this.props.match.params.languageCode) {
            const shortCode = this.props.match.params.shortCode;
            const languageCode = this.props.match.params.languageCode;

            // Try to find the authoring record
            let result = null;
            checklists.forEach(cl => {
                if (cl.checklist.shortCode === shortCode) {
                    // Found
                    if (languageCode in cl.translations && "authoredChecklist" in cl.translations[languageCode]) {
                        // Found authoredChecklist
                        result = {
                            shortCode: shortCode,
                            checklistName: cl.checklist.name,
                            languageCode: languageCode,
                            // authoredChecklist: cl.translations[languageCode]["authoredChecklist"],
                            versionNumber: cl.translations[languageCode]["authoredChecklist"].versionNumber,
                            authoredAt: cl.translations[languageCode]["authoredChecklist"].authoredAt,
                            masterPublishedVersion: cl.checklist.publishedVersion,
                            authoredFromVersion: cl.translations[languageCode]["authoredChecklist"].publishedChecklistVersionId,
                        }
                    } else {
                        result = {
                            shortCode: shortCode,
                            checklistName: cl.checklist.name,
                            languageCode: languageCode,
                            error: "No " + languageCode.toUpperCase() + " translation has been authored yet for checklist: " + shortCode,
                        }
                    }
                }
            });
            if (result === null) {
                result = {
                    checklistName: "Unknown",
                    languageCode: languageCode,
                    error: "Invalid checklist: " + shortCode,
                }
            }
            // console.log("RESULT", result);
            return result;
        } else {
            return null;
        }
    }

    componentDidMount() {
        const organisationSlug = this.getOrganisationSlug();

        // Tell the backend about this component starting up
        this.backendRef = this.context.backend.register("downloads", {
            organisationSlug
        }, (reducerCallback) => {
            // The reducerCallback takes in the old state and returns the new state
            this.setState((oldState) => {
                const oldDL = oldState.downloads;
                const newDL = reducerCallback(oldDL);
                if (newDL) {
                    // Special case where the reducer returns null/undefined, don't do anything
                    return {
                        ...oldState,
                        downloads: newDL,
                    }
                }
            });
        }, (newConnectionStatus) => {
            this.setState((oldState) => {
                return {
                    ...oldState,
                    connectionStatus: newConnectionStatus,
                }
            });
        });

        // We need to trigger a public login if the backend is not logged in with any token
        // this.props.onMount();
        // NO: We are always logged in publicly by default now.

    }
  
    componentWillUnmount() {
        this.context.backend.unRegisterData(this.backendRef);
    }

    getChecklists() {
        if (this.state.downloads === null || !this.state.downloads.checklists) {
            return [];
        } else {
            // For each checklist, make a translations object by languageCode which also contains the authored checklist
            // Additionally, if the checklist.publishedVersion doesnt match the authoredChecklist.publishedChecklistVersionId, then we can mark it as out of date

            const checklists = [];
            Object.values(this.state.downloads.checklists).forEach(cl => {
                const translations = {};

                let numberOfAuthored = 0;
                Object.values(this.state.downloads.checklistTranslations).forEach(clt => {
                    if (clt.checklistId === cl._id) {
                        translations[clt.languageCode] = clt;

                        // Append the authored record
                        if (clt.latestAuthored) {
                            if (clt.latestAuthored in this.state.downloads.authoredChecklists) {
                                numberOfAuthored++;
                                clt.authoredChecklist = this.state.downloads.authoredChecklists[clt.latestAuthored];
                            }
                        }
                    }
                })
                checklists.push({
                    checklist: cl,
                    translations: translations,
                    numberOfAuthored: numberOfAuthored,
                });

            });
            return checklists;
        }
    }

    getAllLanguagesByCode() {
        const languages = {};
        LanguageCodes.forEach(group => {
            group.languages.forEach(lang => {
                languages[lang.code] = {...lang, group: group.name};
            })
        });
        return languages;
    }

    getRelevantLanguagesForChecklists(checklists) {
        const languageCodes = [];
        checklists.forEach(cl => {
            languageCodes.push(...Object.keys(cl.translations));
        });

        const allLanguages = this.getAllLanguagesByCode();

        const relevant = [];
        Object.keys(allLanguages).forEach(languageCode => {
            if (languageCodes.indexOf(languageCode) !== -1) {
                relevant.push(allLanguages[languageCode]);
            }
        })
        return relevant;
    }

    authorLink(organisationSlug, authoredChecklistId, shortCode, languageCode) {
        const url = "/" + organisationSlug + "/downloads/" + shortCode + "/" + languageCode;
        return <Link to={url}>Download</Link>
    }

    displayLinkToLocalChecklist(organisationSlug, key, cl, language) {
        if (language.code in cl.translations) {
            // We have a CLT
            const clt = cl.translations[language.code];
            if (clt.authoredChecklist) {
                // We have an authored checklist in this language
                // Is it up to date
                if (clt.authoredChecklist.publishedChecklistVersionId === cl.checklist.publishedVersion) {
                    // Yes
                    return <div key={key} className="latestVersion">{this.authorLink(organisationSlug, clt.authoredChecklist._id, cl.checklist.shortCode, language.code)}</div>;
                } else {
                    return <div key={key} className="olderVersion">{this.authorLink(organisationSlug, clt.authoredChecklist._id, cl.checklist.shortCode, language.code)}</div>;
                }
            } else {
                return <div key={key} className="notAuthored">Not authored</div>;
            }
        } else {
            return <div key={key} className="notTranslated">Not translated</div>;
        }
    }

    render() {
        const checklists = this.getChecklists();
        const languages = this.getRelevantLanguagesForChecklists(checklists);

        const viewChecklist = this.getCurrentChecklistFromUrlParams(checklists);
        const organisationSlug = this.getOrganisationSlug();

        if (viewChecklist === null) {
            return(
                <div className="downloads">
                    {/*<Text className="header" as="h2">Downloads</Text>*/}

                    <div className="listContainer">
                        <div className="listHeaders">
                            <div className="listHeader">
                                <Text subStyle="emphasis">Country</Text>
                            </div>
                            {(languages.map(lang => (
                                <div className="listHeader" key={lang.name}>
                                    <img className="flag" alt={lang.name} src={lang.flagImage} />
                                    <br />
                                    <Text subStyle="emphasis">{lang.name}</Text>
                                </div>
                            )))}

                        </div>
                    </div>

                    {(checklists.map(cl => (
                        cl.numberOfAuthored > 0 &&
                        <div className="listDataWrapper">
                            
                            <div className="listRowContainer" key={cl.checklist._id}>
                                
                                <div className="listRowData" >
                                    <div className="dataWrapper">
                                        <Text>{cl.checklist.name}</Text>
                                    </div>
                                </div>

                                {(languages.map(lang => (
                                    <div className="listRowData" key={lang.name}>
                                        {this.displayLinkToLocalChecklist(organisationSlug, lang.name + "-" + cl.checklist.shortCode, cl, lang)}
                                    </div>
                                    
                                )))}
                            
                            </div>
                            
                        </div>
                    )))}
                    
                </div>
            )
        } else {
            return(
                <div className="downloadSpecific">

                    <Link to={"/" + organisationSlug + "/downloads"} className="backBtnWrapper">
                        <div className="backBtn">
                            <img src={leftArrow} alt="back" />
                            <Text as="p" subStyle="emphasis">Back</Text>
                        </div>
                    </Link>

                    <Text className="header" as="h2">Download</Text>
                    <div className="rowWrapper">
                        <div className="innerRowWrapper">
                            {viewChecklist.error ? (
                                <p className="error">Error: {viewChecklist.error}</p>
                            ) : (
                                    <>
                                        <div className="textWrapper">
                                            <Text subStyle="emphasis">{viewChecklist.checklistName}</Text>
                                            <Text><Text subStyle="emphasis">Language</Text>: {viewChecklist.languageCode}</Text>
                                        </div>
                                        <div className="textWrapper">
                                            <Text><Text subStyle="emphasis">Version</Text>: {viewChecklist.versionNumber} </Text>
                                            {viewChecklist.masterPublishedVersion !== viewChecklist.authoredFromVersion && (
                                                <p className="warning">Warning: This checklist is out of date in this language and needs re-authoring.</p>
                                            )}
                                            <Text><Text subStyle="emphasis">Authored at</Text>: {format(new Date(viewChecklist.authoredAt), "HH:mm iii do MMM yyyy")}</Text>

                                        </div>

                                        <div className="textWrapper">
                                            <ul>
                                                <li>
                                                    <img src={htmlIcon} alt="icon" />
                                                    <DownloadLatest type="html" organisationSlug={organisationSlug} shortCode={viewChecklist.shortCode} languageCode={viewChecklist.languageCode} />
                                                </li>
                                                <li>
                                                    <img src={pdfIcon} alt="icon" />
                                                    <DownloadLatest type="pdf" organisationSlug={organisationSlug} shortCode={viewChecklist.shortCode} languageCode={viewChecklist.languageCode} />
                                                </li>
                                                <li>
                                                    <img src={excelIcon} alt="icon" />
                                                    <DownloadLatest type="excel" organisationSlug={organisationSlug} shortCode={viewChecklist.shortCode} languageCode={viewChecklist.languageCode} />
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                )}
                        </div>
                    </div>

                    <Text style={{ textAlign: 'center', paddingTop: '20px' }}>Please feel free to bookmark this page for later.</Text>

                </div>
            );
        }
    }

}

Downloads.contextType = BackendContext;
export default Downloads;
