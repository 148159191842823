import React from 'react';
import './styles.scss';
import {Text} from 'vcc-ui';
import { flags, langs } from '../../lib/flags';
import userAdmin from '../../assets/userAdmin.png';

class UserInfo extends React.Component {


    componentDidMount() {
        
    }
  
    componentWillUnmount() {
        
    }

    lookupOrganisation(organisationId) {
        if (this.props.connection) {
            if (this.props.connection.meta) {
                if (this.props.connection.meta.orgs && organisationId in this.props.connection.meta.orgs) {
                    return this.props.connection.meta.orgs[organisationId];
                }
            }
        }
        return null;
    }

    render() {
        const connection = this.props.connection;
        // const clearLoginToken = this.props.clearLoginToken;

        if (connection === null) {
            return null;
        }
        if (connection.status === "CONNECTED" && connection.meta.user) {
            // console.log("USER", connection.meta.user);
            const permissions = connection.meta.user.permissions;
            let isUserAdmin = false;
            if (permissions) {
                if (permissions.isUserAdmin === true) {
                    isUserAdmin = true;
                }
            }
            
            return (
                <>
                    <div className="userInfo">
                        <Text className="names">
                            <Text subStyle="emphasis">User:&nbsp;</Text>
                            {connection.meta.user.firstName} {connection.meta.user.lastName}
                        </Text>
                        <Text className="email">
                            <Text subStyle="emphasis">Email:&nbsp;</Text>
                            {connection.meta.user.email}
                        </Text>

                        {isUserAdmin && (
                            <div className="isUserAdmin" title="User Admin">
                                <img src={userAdmin} alt="User Admin" />
                            </div>
                        )}
                        {permissions.organisations && Object.keys(permissions.organisations).map(orgId => {
                            const org = this.lookupOrganisation(orgId);
                            const isMasterEditor = permissions.organisations[orgId].isMasterEditor === true;
                            const localTransLangs = permissions.organisations[orgId].localTransLangs || [];
                            if (isMasterEditor || localTransLangs.length > 0) {
                                return (
                                    <div key={org._id.toString()}>
                                        <p>{org.name}</p>

                                        {isMasterEditor && (
                                            <div className="isMasterEditor">
                                                <img src={flags['en'].default} alt={org.name + " Master Editor"} title={org.name + " Master Editor"} />
                                            </div>
                                        )}
                                        {localTransLangs.length > 0 && (
                                            <div className="localLangs">
                                                {localTransLangs.map(languageCode => (
                                                    languageCode in flags ? (
                                                        <img key={languageCode} src={flags[languageCode].default} alt={org.name + " " + langs[languageCode].name + " editor"} title={langs[languageCode].name + " editor"} />
                                                    ) : (
                                                        <span>Unknown language: {languageCode}</span>
                                                    )
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}
                        
                    </div>
                </>
            )
        } else {
            return null;
        }
        
    }  
}

export default UserInfo;
