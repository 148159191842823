import React, { useState } from 'react';
import { Text } from 'vcc-ui';
import './styles.scss';


const AddChecklistBar = ({ addChecklist, currentIdents }) => {

    const [checklistName, setChecklistNameInput] = useState("");
    const [checklistIdent, setChecklistIdentInput] = useState("");
    const [formError, setFormError] = useState(false);

    const updateNameText = (txt) => {
        setChecklistNameInput(txt);
        // Replace anything that isn't a a-zA-Z0-9 with a -
        let ident = txt.replace(/[^a-zA-Z0-9]/g, "-");
        ident = ident.toLowerCase();
        updateIdentText(ident);
    }

    const updateIdentText = (ident) => {
        setChecklistIdentInput(ident);

        if (currentIdents.indexOf(ident) !== -1) {
            setFormError(true);
        } else {
            setFormError(false);
        }
    }

    const submitNewChecklist = (e) => {
        e.preventDefault();
        addChecklist(checklistName, checklistIdent);
        setChecklistNameInput("");
        setChecklistIdentInput("");
    }

    return (
        <div className="addChecklist">

            <form onSubmit={(e) => submitNewChecklist(e)} className="addChecklistWrapper">

                <div className="inputWrapper">
                    <input
                        id="checklistName"
                        className="dataInput"
                        type="text"
                        value={checklistName}
                        onChange={(e) => updateNameText(e.target.value)}
                        placeholder="Name"
                    />
                </div>

                <div className="inputWrapper">
                    <input
                        id="checklistIdent"
                        className={(formError && checklistIdent.length >= 0) ? "dataInput inputError" : "dataInput"}
                        type="text"
                        value={checklistIdent}
                        onChange={(e) => updateIdentText(e.target.value)}
                        placeholder="Identifier"
                        disabled
                    />
                </div>

                <button type="submit" disabled={formError ? "disabled" : ""}>ADD</button>
            </form>
            
            { formError ? 
                <div className="errorText">
                    <Text>A checklist with this name already exist.</Text>
                </div> 
            : null }

        </div>
    );

}

export {
    AddChecklistBar
};
