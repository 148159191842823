import React from 'react';
import './styles.scss';
import { Button, Text } from 'vcc-ui';
import EdiText from 'react-editext';

export default function VariableOverride({variableId, defaultText, overrideText, openEdit, stopEdit, isEditing, setOverride, clearOverride}) {

    const overridden = typeof overrideText !== "undefined";

    return (
        <tr className="variableOverride">
            <td className="idCol">
                <Text>{variableId}</Text>
            </td>
            <td className={overridden ? "defaultText overridden" : "defaultText"}>
                <div className="defaultTextWrapper">
                    <Text as="p">{defaultText}</Text>
                    {!overridden &&
                        <Button onClick={() => openEdit()} >
                            Override
                        </Button>
                    }
                </div>
            </td>
            <td className={isEditing ? "overrideText editing" : "overrideText"} >
                {
                    isEditing ? (
                        <EdiText
                            type='text'
                            onSave={(val) => { setOverride(val); }}
                            onCancel={() => { stopEdit(); }}
                            
                            cancelOnEscape
                            submitOnEnter                  

                            editing={true}
                            value={overridden ? overrideText : defaultText}
                            saveButtonClassName="custom-save-button"
                            cancelButtonClassName="custom-cancel-button"
                            inputProps={{
                                // className: 'textarea',
                                style: {
                                    outline: 'none'
                                },
                                rows: 1
                            }}
                            viewProps={{
                                className: 'valueText'
                            }}
                        />
                    ) : (
                        overridden && (
                            <div className="overrideWrapper">
                                <span onClick={() => openEdit()}>
                                        <Text>{overrideText}</Text>
                                </span>
                                <Button onClick={() => clearOverride()}>Clear</Button>
                            </div>
                        )
                    )
                }
            </td>
        </tr>
    );

}
