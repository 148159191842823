import React from 'react';
import './styles.scss';
import { Text } from 'vcc-ui';
import { getEndpoint } from '../../lib/endpoint';


export default function DownloadLatest({organisationSlug, type, shortCode, languageCode}) {

    const backendEndpoint = getEndpoint();
    
    const downloadUrl = backendEndpoint + "/" + organisationSlug + "/download/latest/" + shortCode + "/" + languageCode + "/" + type;
    
    if (type === "html") {
        return (
            <div className="downloadLatest">
                <Text>
                    <a target="_blank" rel="noreferrer" href={downloadUrl}>Download HTML</a>
                </Text>
            </div>
        )
    } else if (type === "pdf") {
        return (
            <div className="downloadLatest">
                <Text>
                    <a target="_blank" rel="noreferrer" href={downloadUrl}>Download PDF</a>
                </Text>
            </div>
        )
    } else if (type === "excel") {
        return (
            <div className="downloadLatest">
                <Text>
                    <a target="_blank" rel="noreferrer" href={downloadUrl}>Download Excel</a>
                </Text>
            </div>
        )
    }
}
