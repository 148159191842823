// WARNING - DO NOT EDIT THIS FILE
// WARNING - THIS FILE IS AUTOMATICALLY GENERATED BY npm run downloadFlags

export const langs = {};
export const flags = {};
flags['en-GB'] = require('../assets/flags/en-GB.png');
langs['en-GB'] = {"name":"English / UK","code":"en-GB","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/gb.png"};
flags['en-IE'] = require('../assets/flags/en-IE.png');
langs['en-IE'] = {"name":"English / Ireland","code":"en-IE","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/ie.png"};
flags['en-AU'] = require('../assets/flags/en-AU.png');
langs['en-AU'] = {"name":"English / Australia","code":"en-AU","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/au.png"};
flags['en-US'] = require('../assets/flags/en-US.png');
langs['en-US'] = {"name":"English / USA","code":"en-US","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/us.png"};
flags['en-CA'] = require('../assets/flags/en-CA.png');
langs['en-CA'] = {"name":"English / Canada","code":"en-CA","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/ca.png"};
flags['en-ZA'] = require('../assets/flags/en-ZA.png');
langs['en-ZA'] = {"name":"English / South Africa","code":"en-ZA","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/za.png"};
flags['sv-SE'] = require('../assets/flags/sv-SE.png');
langs['sv-SE'] = {"name":"Swedish","code":"sv-SE","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/se.png"};
flags['no-NO'] = require('../assets/flags/no-NO.png');
langs['no-NO'] = {"name":"Norwegian","code":"no-NO","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/no.png"};
flags['fi-FI'] = require('../assets/flags/fi-FI.png');
langs['fi-FI'] = {"name":"Finnish","code":"fi-FI","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/fi.png"};
flags['da-DK'] = require('../assets/flags/da-DK.png');
langs['da-DK'] = {"name":"Danish","code":"da-DK","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/dk.png"};
flags['nl-NL'] = require('../assets/flags/nl-NL.png');
langs['nl-NL'] = {"name":"Dutch - Netherlands","code":"nl-NL","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/nl.png"};
flags['nl-BE'] = require('../assets/flags/nl-BE.png');
langs['nl-BE'] = {"name":"Dutch - Belgium","code":"nl-BE","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/be.png"};
flags['fr-FR'] = require('../assets/flags/fr-FR.png');
langs['fr-FR'] = {"name":"French - France","code":"fr-FR","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/fr.png"};
flags['fr-BE'] = require('../assets/flags/fr-BE.png');
langs['fr-BE'] = {"name":"French - Belgium","code":"fr-BE","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/be.png"};
flags['fr-CA'] = require('../assets/flags/fr-CA.png');
langs['fr-CA'] = {"name":"French - Canada","code":"fr-CA","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/ca.png"};
flags['fr-CH'] = require('../assets/flags/fr-CH.png');
langs['fr-CH'] = {"name":"French - Switzerland","code":"fr-CH","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/ch.png"};
flags['de-DE'] = require('../assets/flags/de-DE.png');
langs['de-DE'] = {"name":"German - Germany","code":"de-DE","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/de.png"};
flags['de-AT'] = require('../assets/flags/de-AT.png');
langs['de-AT'] = {"name":"German - Austria","code":"de-AT","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/at.png"};
flags['de-CH'] = require('../assets/flags/de-CH.png');
langs['de-CH'] = {"name":"German - Switzerland","code":"de-CH","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/ch.png"};
flags['pl-PL'] = require('../assets/flags/pl-PL.png');
langs['pl-PL'] = {"name":"Polish","code":"pl-PL","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/pl.png"};
flags['cs-CZ'] = require('../assets/flags/cs-CZ.png');
langs['cs-CZ'] = {"name":"Czech","code":"cs-CZ","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/cz.png"};
flags['hu-HU'] = require('../assets/flags/hu-HU.png');
langs['hu-HU'] = {"name":"Hungarian","code":"hu-HU","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/hu.png"};
flags['it-IT'] = require('../assets/flags/it-IT.png');
langs['it-IT'] = {"name":"Italian - Italy","code":"it-IT","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/it.png"};
flags['it-CH'] = require('../assets/flags/it-CH.png');
langs['it-CH'] = {"name":"Italian - Switzerland","code":"it-CH","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/ch.png"};
flags['es-ES'] = require('../assets/flags/es-ES.png');
langs['es-ES'] = {"name":"Spanish","code":"es-ES","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/es.png"};
flags['pt-PT'] = require('../assets/flags/pt-PT.png');
langs['pt-PT'] = {"name":"Portuguese / Portugal","code":"pt-PT","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/pt.png"};
flags['pt-BR'] = require('../assets/flags/pt-BR.png');
langs['pt-BR'] = {"name":"Portuguese / Brazil","code":"pt-BR","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/br.png"};
flags['el-GR'] = require('../assets/flags/el-GR.png');
langs['el-GR'] = {"name":"Greek","code":"el-GR","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/gr.png"};
flags['ru-RU'] = require('../assets/flags/ru-RU.png');
langs['ru-RU'] = {"name":"Russian","code":"ru-RU","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/ru.png"};
flags['tr-TR'] = require('../assets/flags/tr-TR.png');
langs['tr-TR'] = {"name":"Turkish","code":"tr-TR","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/tr.png"};
flags['zh-CN'] = require('../assets/flags/zh-CN.png');
langs['zh-CN'] = {"name":"Chinese / Simplified for China","code":"zh-CN","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/cn.png"};
flags['zh-TW'] = require('../assets/flags/zh-TW.png');
langs['zh-TW'] = {"name":"Chinese / Traditional for Taiwan","code":"zh-TW","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/tw.png"};
flags['ja-JP'] = require('../assets/flags/ja-JP.png');
langs['ja-JP'] = {"name":"Japanese","code":"ja-JP","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/jp.png"};
flags['ko-KR'] = require('../assets/flags/ko-KR.png');
langs['ko-KR'] = {"name":"Korean","code":"ko-KR","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/kr.png"};
flags['th-TH'] = require('../assets/flags/th-TH.png');
langs['th-TH'] = {"name":"Thai","code":"th-TH","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/th.png"};
flags['ms-MY'] = require('../assets/flags/ms-MY.png');
langs['ms-MY'] = {"name":"Malay","code":"ms-MY","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/my.png"};
flags['en'] = require('../assets/flags/en.png');
langs['en'] = {"name":"English","code":"en","flagImage":"https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/gb.png"};