
import React from 'react';
import './styles.scss';

export default function PermissionRequired({history, redirectUrl, permissionName}) {

    let thisRedirectUrl = redirectUrl;
    if (!redirectUrl) {
        // Take from pathname
        thisRedirectUrl = window.location.pathname;
    }
    const pushUrl = "/login?redirectUrl=" + encodeURIComponent(thisRedirectUrl);

    return (
        <div className="permissionRequired">
            <h3>Access Denied</h3>
            <p>You do not have the correct role or enough permissions to view this page.</p>
            <p>Lacking Permission/Role: <strong>{permissionName}</strong></p>
            <p>You can try <button onClick={() => history.push(pushUrl)}>logging in</button> to a different account!</p>
        </div>
    )

}