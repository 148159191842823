import React from 'react';
import { Text } from 'vcc-ui';
import './styles.scss';

import { flags } from '../../lib/flags';


export default function LanguageFlag({languageCode, name, flagImage, clickedLanguage}) {

    const userClickedLanguage = (e, lang) => {
        if (clickedLanguage) {
            e.preventDefault();
            clickedLanguage(lang);
        }
    }

    return (
        <div className="languageFlag" onClick={(e) => userClickedLanguage(e, languageCode)}>
            {languageCode in flags && (
                <img src={flags[languageCode].default} alt={name} />
            )}
            <Text>{name} ({languageCode})</Text>
        </div>
    );

}
