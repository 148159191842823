import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { BackendContext } from '../BackendContext';
import UserRegistrations from '../UserRegistrations';
import { format } from 'date-fns';
import { Text } from 'vcc-ui';
import { flags, langs } from '../../lib/flags';
import leftArrow from '../../assets/left-arrow.svg';
import LoginRequired from '../LoginRequired';
import PermissionRequired from '../PermissionRequired';


class LocalChecklistList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            master: null,
            connectionStatus: null,
        };
    }

    getCurrentlyLoggedInUserFromConnectionStatus() {
        if (this.state.connectionStatus) {
            if (this.state.connectionStatus.meta) {
                if (this.state.connectionStatus.meta.user) {
                    const idp = this.state.connectionStatus.meta.user.idp;
                    if (idp !== "none") {
                        return this.state.connectionStatus.meta.user;
                    }
                }
            }
        }
        return null;
    }

    getOrganisationSlug() {
        let slug = null;
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.org) {
                    slug = this.props.match.params.org;
                }
            }
        }
        return slug;
    }

    componentDidMount() {
        const organisationSlug = this.getOrganisationSlug();
        // Tell the backend about this component starting up
        this.backendRef = this.context.backend.register("localChecklists", {
            organisationSlug,
            languageCode: this.getLanguageCode(),
        }, (reducerCallback) => {
            // The reducerCallback takes in the old state and returns the new state
            this.setState((oldState) => {
                const oldCLL = oldState.master;
                const newCLL = reducerCallback(oldCLL);
                if (newCLL) {
                    // Special case where the reducer returns null/undefined, don't do anything
                    return {
                        ...oldState,
                        master: newCLL,
                    }
                }
            });
        }, (newConnectionStatus) => { // Object of keys status (string), tokenExpiry (date)
            this.setState((oldState) => {
                return {
                    ...oldState,
                    connectionStatus: newConnectionStatus,
                }
            });
        });
    }

    componentWillUnmount() {
        this.context.backend.unRegisterData(this.backendRef);
    }

    getMyConnectionId() {
        if (this.state.connectionStatus === null || !this.state.connectionStatus.meta || this.state.connectionStatus.meta === null) {
            return null;
        } else {
            return this.state.connectionStatus.meta.connectionId;
        }
    }

    getChecklists() {
        if (this.state.master === null || !this.state.master.checklists) {
            return [];
        } else {
            // Merge the checklist versions in to each checklist
            const checklists = [];
            Object.values(this.state.master.checklists).forEach(cl => {
                let published = null;
                if (cl.publishedVersion && cl.publishedVersion in this.state.master.checklistVersions) {
                    published = this.state.master.checklistVersions[cl.publishedVersion];
                    // Also the lastUpdatedBy on the editing and published versions
                    if (published.lastUpdatedBy && published.lastUpdatedBy in this.state.master.users) {
                        published.lastUpdatedUser = this.state.master.users[published.lastUpdatedBy];
                    } else {
                        published.lastUpdatedUser = null;
                    }
                }
                let editing = null;
                if (cl.editingVersion && cl.editingVersion in this.state.master.checklistVersions) {
                    editing = this.state.master.checklistVersions[cl.editingVersion];
                    // Also the lastUpdatedBy on the editing and published versions
                    if (editing.lastUpdatedBy && editing.lastUpdatedBy in this.state.master.users) {
                        editing.lastUpdatedUser = this.state.master.users[editing.lastUpdatedBy];
                    } else {
                        editing.lastUpdatedUser = null;
                    }
                }

                // Also the createdBy and lastUpdatedBy
                let createdUser = null;
                if (cl.createdBy && cl.createdBy in this.state.master.users) {
                    createdUser = this.state.master.users[cl.createdBy];
                }
                let lastUpdatedUser = null;
                if (cl.lastUpdatedBy && cl.lastUpdatedBy in this.state.master.users) {
                    lastUpdatedUser = this.state.master.users[cl.lastUpdatedBy];
                }

                // Also the checklistTranslation
                let checklistTranslation = null;
                if (cl.checklistTranslationId && cl.checklistTranslationId in this.state.master.checklistTranslations) {
                    checklistTranslation = this.state.master.checklistTranslations[cl.checklistTranslationId];
                }

                if (checklistTranslation !== null) {
                    // Also need to expand the checklistTranslation so that it contains authoredChecklist
                    let authoredChecklist = null;
                    if (checklistTranslation.latestAuthored && checklistTranslation.latestAuthored in this.state.master.authoredChecklists) {
                        authoredChecklist = this.state.master.authoredChecklists[checklistTranslation.latestAuthored];
                    }
                    checklistTranslation.authoredChecklist = authoredChecklist;

                    // And lastUpdatedUser
                    let lastUpdatedUser = null;
                    if (checklistTranslation.lastUpdatedBy && checklistTranslation.lastUpdatedBy in this.state.master.users) {
                        lastUpdatedUser = this.state.master.users[checklistTranslation.lastUpdatedBy];
                    }
                    checklistTranslation.lastUpdatedUser = lastUpdatedUser;
                }

                // Also need to expand the authoredChecklist so that it contains the authoredUser user
                if (checklistTranslation !== null && checklistTranslation.authoredChecklist !== null) {
                    let authoredUser = null;
                    if (checklistTranslation.authoredChecklist.authoredBy && checklistTranslation.authoredChecklist.authoredBy in this.state.master.users) {
                        authoredUser = this.state.master.users[checklistTranslation.authoredChecklist.authoredBy];
                    }
                    checklistTranslation.authoredChecklist.authoredUser = authoredUser;
                }

                checklists.push({
                    ...cl,
                    published,
                    editing,
                    createdUser,
                    lastUpdatedUser,
                    checklistTranslation,
                });
            });
            return checklists;
        }
    }

    getUserOnThisPage() {
        const languageCode = this.getLanguageCode();
        if (this.state.master === null || !this.state.master._regs) {
            return {};
        } else {
            // Find the sockets that have at least one view matching this page
            const list = {};
            Object.keys(this.state.master._regs).map(socketId => {
                const reg = this.state.master._regs[socketId];
                let hasAppropriateView = false;
                Object.keys(reg.views).forEach(viewId => {
                    const view = reg.views[viewId];
                    if (view.listenType === "localChecklists" && view.listenParams.languageCode === languageCode) {
                        hasAppropriateView = true;
                    }
                })
                if (hasAppropriateView) {
                    list[socketId] = reg;
                }
                return null;
            });
            return list;
        }
    }

    getLanguageCode() {
        return this.props.match.params.languageCode;
    }

    getUsersEditingChecklistTranslation(clId, languageCode) {
        if (this.state.master === null || !this.state.master._regs) {
            return {};
        } else {
            // Find the sockets that have at least one view matching this page
            const list = {};
            Object.keys(this.state.master._regs).map(socketId => {
                const reg = this.state.master._regs[socketId];
                let hasAppropriateView = false;
                Object.keys(reg.views).forEach(viewId => {
                    const view = reg.views[viewId];
                    if (view.listenType === "localChecklist" && view.listenParams.checklistId === clId && view.listenParams.languageCode === languageCode) {
                        hasAppropriateView = true;
                    }
                })
                if (hasAppropriateView) {
                    list[socketId] = reg;
                }
                return null;
            });
            return list;
        }
    }

    getLanguageName() {
        const code = this.getLanguageCode();
        if (code in langs) {
            return langs[code].name;
        }
    }

    render() {
        const checklists = this.getChecklists();
        // console.log("CHECKLISTS", checklists);

        // const usersOnThisPage = this.getUserOnThisPage();
        const myConnectionId = this.getMyConnectionId();
        const languageCode = this.getLanguageCode();
        const languageName = this.getLanguageName();

        const organisationSlug = this.getOrganisationSlug();

        const organisation = this.props.organisation;
        const user = this.getCurrentlyLoggedInUserFromConnectionStatus();

        // Note: Organisation should always be set on a page with org slug in the url
        if (organisation === null) {
            return null;
        }
        
        if (user === null) {
            return (
                <div className="users">
                    <LoginRequired history={this.props.history} />
                </div>
            )
        } else {
            // We are logged in, check organisation permissions
            if (!(organisation._id in user.permissions.organisations) || !user.permissions.organisations[organisation._id].localTransLangs || user.permissions.organisations[organisation._id].localTransLangs.indexOf(languageCode) === -1) {
                return (
                    <div className="users">
                        <PermissionRequired history={this.props.history} permissionName={organisation.name + ": Local Editor (" + languageName + ")"} />
                    </div>
                )
            }
        }

        return (
            <div className="checklistList localChecklistList">

                <Link to={"/" + organisationSlug + "/local"} className="backBtnWrapper">
                    <div className="backBtn">
                        <img src={leftArrow} alt="back" />
                        <Text as="p" subStyle="emphasis">Back</Text>
                    </div>
                </Link>

                <div className="checklistTitle">
                    {languageCode in flags && (
                        <img src={flags[languageCode].default} alt={languageCode} />
                    )}
                    <Text subStyle="emphasis">Local Checklists ({languageCode})</Text>
                </div>

                <div className="listContainer">
                    <div className="listHeaders">
                        <div className="listHeader">
                            <Text subStyle="emphasis">Status</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">ID</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Name</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Master Published</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Translations Updated</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Last Authored</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Edit</Text>
                        </div>
                    </div>

                    <div className="listDataWrapper">
                        {checklists.map((item, index) => {
                            // console.log("ITEM", item);
                            const usersEditingChecklistTranslation = this.getUsersEditingChecklistTranslation(item._id, languageCode);
                            const isAuthored = (item.checklistTranslation && item.checklistTranslation.authoredChecklist);
                            const classNames = [];
                            let statusText = "";

                            classNames.push("listRowContainer");

                            if (item.published === null) {
                                classNames.push("notPublished");
                                statusText = "Not published";
                            } else {
                                classNames.push("isPublished");
                                if (isAuthored) {
                                    classNames.push("authored");
                                    statusText = "Authored";
                                    const isLatest = (item.checklistTranslation.authoredChecklist.publishedChecklistVersionId === item.published._id);
                                    if (isLatest) {
                                        classNames.push("isLatest");
                                        statusText = "Published";
                                    } else {
                                        classNames.push("notLatest");
                                        statusText = "Not latest";
                                    }
                                } else {
                                    classNames.push("notAuthored");
                                    statusText = "Not authored";
                                }
                            }

                            return (
                                <div className={classNames.join(" ")} key={item._id}>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            <Text>{statusText}</Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            <Text>{item.shortCode}</Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            <Text>{item.name}</Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            <Text>
                                                {item.published !== null ? (
                                                    <>
                                                        {item.published.lastUpdatedUser.firstName} {item.published.lastUpdatedUser.lastName} {item.published.lastUpdatedUser.email}
                                                        <br />
                                                        Version {item.published.versionNumber}
                                                        <br />
                                                        {format(new Date(item.published.lastUpdatedAt), "HH:mm iii do MMM yyyy")}
                                                    </>
                                                ) : (
                                                        <>Not published yet</>
                                                    )}
                                            </Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            <Text>
                                                {(item.checklistTranslation && item.checklistTranslation.lastUpdatedUser) ? (
                                                    <>
                                                        {item.checklistTranslation.lastUpdatedUser.firstName} {item.checklistTranslation.lastUpdatedUser.lastName} {item.checklistTranslation.lastUpdatedUser.email}
                                                        <br />
                                                        {format(new Date(item.checklistTranslation.lastUpdatedAt), "HH:mm iii do MMM yyyy")}
                                                    </>
                                                ) : (
                                                        <>Not translated yet</>
                                                    )}
                                            </Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            <Text>
                                                {(item.checklistTranslation && item.checklistTranslation.authoredChecklist) ? (
                                                    <>
                                                        {item.checklistTranslation.authoredChecklist.authoredUser.firstName} {item.checklistTranslation.authoredChecklist.authoredUser.lastName} {item.checklistTranslation.authoredChecklist.authoredUser.email}
                                                        <br />
                                                        Version {item.checklistTranslation.authoredChecklist.versionNumber}
                                                        <br />
                                                        {format(new Date(item.checklistTranslation.authoredChecklist.authoredAt), "HH:mm iii do MMM yyyy")}
                                                    </>
                                                ) : (
                                                        <>Not authored yet</>
                                                    )}
                                            </Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            {item.published !== null && (
                                                <>
                                                    <Link className="button" to={`/${organisationSlug}/local/${languageCode}/${item._id}`}>
                                                        <Text>Edit</Text>
                                                    </Link>

                                                    <UserRegistrations myConnectionId={myConnectionId} regs={usersEditingChecklistTranslation} alignRight={false} />
                                                </>
                                            )}
                                            
                                        </div>
                                    </div>

                                </div>
                            );
                        })}
                    </div>
                </div>

            </div>
        );
    }

}
LocalChecklistList.contextType = BackendContext;
export default LocalChecklistList;

