import React, { useEffect } from 'react';
import { Button, Text } from 'vcc-ui';
import './styles.scss';

import ClientOAuth2 from 'client-oauth2';
 
const volvoAuth = new ClientOAuth2({
  clientId: 'fde6d343-0a61-439b-aee2-1ce763c9957e',
  accessTokenUri: 'https://login.microsoftonline.com/81fa766e-a349-4867-8bf4-ab35e250a08f/oauth2/v2.0/token',
  authorizationUri: 'https://login.microsoftonline.com/81fa766e-a349-4867-8bf4-ab35e250a08f/oauth2/v2.0/authorize',
  redirectUri: window.location.origin + "/login", // You MUST redirect back to the login page!
  scopes: ['fde6d343-0a61-439b-aee2-1ce763c9957e/.default'],
  // scopes: ['User.read openid email'] // Combinations of these things didn't work
});

const Login = ({setLoginToken, clearLoginToken, errorMessage, connection, history, match}) => {
    
    // Rather than auto redirecting to the appropriate pages, we can display user permissions here and links to do appropriate things
    /*
    useEffect(() => {
        if (connection !== null) {
            if (connection.meta && connection.meta.user && connection.meta.user.idp && connection.meta.user.idp !== "none") {
                // TODO Redirect based on user access rights here?
                // console.log("Redirecting to home", connection);
                setTimeout(() => {
                    history.push("/");
                }, 10);
            }
        }
    }, [connection, history]);
    */

    /* Remove test account access from stage environment to not have any breach attempts,
    if we wanna add this again, just add || window.location.hostname === "stage-usedcaradmin.volvocars.biz".
    This was removed by Jesper 28/02-2023 */
    let showTestAccounts = false;
    if (window.location.hostname === "localhost") {
        showTestAccounts = true;
    }

    useEffect(() => {
        const redirectUrl = new URLSearchParams(window.location.search).get("redirectUrl");
        // Must start with "/"
        if (redirectUrl && redirectUrl.length > 4 && redirectUrl.substring(0,1) === "/") {
            // console.log("Found redirectUrl, storing: " + redirectUrl);
            window.sessionStorage.setItem("redirectUrl", redirectUrl);
        }
    }, []);

    const doLogin = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Redirect window to Volvo Login endpoint
        const endpoint = volvoAuth.token.getUri();
        window.location.href = endpoint;
    }

    const setAccessTokenAndRedirect = (accessToken) => {
        setLoginToken(accessToken);

        // If there is a redirectUrl, push to here
        const redirectUrl = window.sessionStorage.getItem("redirectUrl");
        if (redirectUrl) {
            window.sessionStorage.removeItem("redirectUrl");
            history.push(redirectUrl);
        }
    }

    if (window.location.hash && window.location.hash.length > 0) {
        // There is a hash, try to decode it now
        volvoAuth.token.getToken(window.location).then(user => {
            // console.log("window.location.hash", window.location.hash);
            window.location.hash = "";
            // alert("Successful login");
            // console.log("window.location.hash", window.location.hash);
            setAccessTokenAndRedirect(user.accessToken);

        }).catch(e => {
            console.error(e);
            alert(e.message);
        });
    }

    let loggedInUser = null;
    if (connection && connection.meta && connection.meta.user) {
        if (connection.meta.user.idp && connection.meta.user.idp !== "none") {
            loggedInUser = connection.meta.user;
        }
    }

    return (
        <div className="loginForm">
            { loggedInUser ? (
                <div className="userDetails">
                    <p>Logged in as {loggedInUser.firstName} {loggedInUser.lastName}</p>

                    <button onClick={() => clearLoginToken()}>Logout</button>
                </div>
            ) : (
                <>
                    { errorMessage && (
                        <div className="loginError">Error: {errorMessage}</div>
                    )}
                    <form onSubmit={(e) => doLogin(e)}>
                        <Button type="submit">Login with Volvo CDSID</Button>
                    </form>

                    {showTestAccounts && (
                        <>
                            <br />

                            <Text subStyle="emphasis">
                                Or use one of these test accounts.
                            </Text>

                            <br />

                            <ul>
                                <li>
                                    <button onClick={() => setAccessTokenAndRedirect('test-mm')}>Mike Master - Master admin</button>
                                </li>
                                <li>
                                    <button onClick={() => setAccessTokenAndRedirect('test-st')}>Segrid Translator - SE translator</button>
                                </li>
                                <li>
                                    <button onClick={() => setAccessTokenAndRedirect('test-dt')}>Denis Translator - DE translator</button>
                                </li>
                                <li>
                                    <button onClick={() => setAccessTokenAndRedirect('test-at')}>Ali Translator - SE &amp; DE translator</button>
                                </li>
                                <li>
                                    <button onClick={() => setAccessTokenAndRedirect('test-dd')}>Dave Downloader - Download only</button>
                                </li>
                                <li>
                                    <button onClick={() => setAccessTokenAndRedirect('test-aa')}>Ali Access - Full access</button>
                                </li> 
                            </ul>
                        </>
                    )}
                </>
            )}
            
        </div>
    );

}

export {
    Login
};
