import React from 'react';
import './styles.scss';
import { format } from 'date-fns';
import { BackendContext } from '../BackendContext';
import { Link } from 'react-router-dom';
import { Text, Button, TextInput } from 'vcc-ui';
import UserEdit from '../UserEdit';
import { flags, langs } from '../../lib/flags';
import userAdmin from '../../assets/userAdmin.png';
import closeIcon from '../../assets/close.svg';
import LoginRequired from '../LoginRequired';
import PermissionRequired from '../PermissionRequired';

class Users extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            connectionStatus: null,
            users: null,
            showDisabled: false,
            volvoEmail: "",
            showPermission: null
        };
    }

    getCurrentlyLoggedInUserFromConnectionStatus() {
        if (this.state.connectionStatus) {
            if (this.state.connectionStatus.meta) {
                if (this.state.connectionStatus.meta.user) {
                    const idp = this.state.connectionStatus.meta.user.idp;
                    if (idp !== "none") {
                        return this.state.connectionStatus.meta.user;
                    }
                }
            }
        }
        return null;
    }

    getSelectedUserFromParam() {
        if (this.state.users !== null && this.state.users.users) {
            if (this.props.match) {
                if (this.props.match.params) {
                    if (this.props.match.params.userId) {
                        if (this.props.match.params.userId in this.state.users.users) {
                            return this.state.users.users[this.props.match.params.userId];
                        }
                    }
                }
            }
        }
        return null;
    }

    getAllOrganisations() {
        if (this.state.users !== null && this.state.users.orgs) {
            return Object.values(this.state.users.orgs);
        }
        return null;
    }

    componentDidMount() {
        // Tell the backend about this component starting up
        this.backendRef = this.context.backend.register("users", {
            
        }, (reducerCallback) => {
            // The reducerCallback takes in the old state and returns the new state
            this.setState((oldState) => {
                const oldUsers = oldState.users;
                const newUsers = reducerCallback(oldUsers);
                if (newUsers) {
                    // Special case where the reducer returns null/undefined, don't do anything
                    return {
                        ...oldState,
                        users: newUsers,
                    }
                }
            });
        }, (newConnectionStatus) => {
            this.setState((oldState) => {
                return {
                    ...oldState,
                    connectionStatus: newConnectionStatus,
                }
            });
        });
    }
  
    componentWillUnmount() {
        try {
            this.context.backend.unRegisterData(this.backendRef);
        } catch(e) {
            console.warn("Ignoring this error", e);
        }
    }

    getUsers() {
        if (this.state.users === null || !this.state.users.users) {
            return [];
        } else {
            // TODO Add filter here based on some filter text in the state of this component

            

            const relevant = [];
            Object.keys(this.state.users.users).forEach(userId => {
                const user = this.state.users.users[userId];
                if (this.state.showDisabled === false && user.disabled === true) {
                    // Ignore
                } else {
                    relevant.push(user);
                }
            })
            return relevant;
        }
    }

    renderPermissions(permissions) {
        const orgs = this.getAllOrganisations();
        
        // Update: 2022-05-04 I have no idea why this is commented out.  
        // I'm guessing it was because it clutters up the users page to show all of the access people have.

        // Update: 2022-05-25 Anne has complained that permissions are not rendering properly, so putting this code back.
        
        // Note: Master editors and local trans are only valid within the scope of an organisation anyway, so leaving this commented
        // let isMasterEditor = false; 
        let isUserAdmin = false;
        // let localTransLangs = [];
        if (permissions) {
            /*
            if (permissions.isMasterEditor === true) {
                isMasterEditor = true;
            }
            */
            if (permissions.isUserAdmin === true) {
                isUserAdmin = true;
            }
            /*
            if (permissions.localTransLangs && permissions.localTransLangs instanceof Array) {
                localTransLangs = permissions.localTransLangs;
            }
            */
        }

        let foundOrgPerms = false;
        return (
            <div className="permissions">
                {isUserAdmin && (
                    <div className="isUserAdmin" title="User Admin">
                        <Text as="p" subStyle="emphasis" className="editorHeader">User admin</Text>
                        <img src={userAdmin} alt="User Admin" />
                    </div>
                )}
                {orgs.map(organisation => {
                    let isMasterEditor = false;
                    let localTransLangs = [];
                    if (permissions.organisations && permissions.organisations[organisation._id]) {
                        isMasterEditor = permissions.organisations[organisation._id].isMasterEditor === true;
                        localTransLangs = permissions.organisations[organisation._id].localTransLangs || [];

                        if (isMasterEditor || localTransLangs.length > 0) {
                            foundOrgPerms = true;
                        }
                    }
                    return (
                        (isMasterEditor || localTransLangs.length > 0) && (
                            <div key={organisation._id}>
                                <div>Org: {organisation.name}</div>

                                {isMasterEditor && (
                                    <div className="isMasterEditor">
                                        <Text as="p" subStyle="emphasis" className="editorHeader">Master editor</Text>
                                        <img src={flags['en'].default} alt="Master Editor" title="Master Editor" />
                                    </div>
                                )}
                                {localTransLangs.length > 0 && (
                                    <div className="localLangs">
                                        <Text as="p" subStyle="emphasis" className="editorHeader">Local editor</Text>
                                        {localTransLangs.map(languageCode => (
                                            languageCode in flags ? (
                                                <img key={languageCode} src={flags[languageCode].default} alt={langs[languageCode].name + " editor"} title={langs[languageCode].name + " editor"} />
                                            ) : (
                                                <span>Unknown language: {languageCode}</span>
                                            )
                                        ))}
                                    </div>
                                )}

                            </div>
                        )
                    )
                })}
                
                {!isUserAdmin && !foundOrgPerms && (
                    <div className="downloadOnly">Download only</div>
                )}

            </div>
        )
    }

    updateUserOrgPermission(userId, organisationId, permissionName, permissionKey, permissionValue) {
        console.log("UpdatePermission", {userId, organisationId, permissionName, permissionKey, permissionValue});
        this.context.backend.updateUserOrgPermission(userId, organisationId, permissionName, permissionKey, permissionValue);
    }

    enableDisableUser(userId, disableUser) {
        if (disableUser) {
            this.context.backend.disableUser(userId);
        } else {
            this.context.backend.enableUser(userId);
        }
    }

    createUser(e) {
        e.preventDefault(); 
        this.context.backend.createUser(this.state.volvoEmail);
        this.setState({volvoEmail: ""});
    }

    openPermisson(i) {
        this.setState({
            showPermission: i
        })
    }

    render() {
        const users = this.getUsers();
        const selectedUser = this.getSelectedUserFromParam();
        const organisations = this.getAllOrganisations();

        const user = this.getCurrentlyLoggedInUserFromConnectionStatus();
        if (user === null) {
            return (
                <div className="users">
                    <LoginRequired history={this.props.history} />
                </div>
            )
        } else {
            // We are logged in, check permissions
            if (user.permissions.isUserAdmin !== true) {
                return (
                    <div className="users">
                        <PermissionRequired history={this.props.history} permissionName="User Admin" />
                    </div>
                )
            }
        }


        return (
            <div className="users">

                {selectedUser !== null && (
                    <UserEdit 
                        user={selectedUser} 
                        organisations={organisations} 
                        enableDisableUser={(disabled) => this.enableDisableUser(selectedUser._id, disabled)} 
                        updateUserOrgPermission={(organisationId, permissionName, permissionKey, permissionValue) => this.updateUserOrgPermission(selectedUser._id, organisationId, permissionName, permissionKey, permissionValue)} 
                    />
                )}

                {users !== null && !selectedUser && (
                    <>
                        <Text style={{fontSize: "26px", textAlign: 'center'}} as="h2">Users</Text>
                        
                        <div style={{paddingBottom: '20px'}}>
                            <input 
                                value="1" 
                                checked={this.state.showDisabled ? "checked" : ""} 
                                onChange={(e) => this.setState({ showDisabled: e.target.checked })} 
                                type="checkbox" 
                                name="showDisabled" />
                            
                            <Text style={{display: 'inline', marginLeft: '5px'}}>Show disabled accounts</Text>
                        </div>

                        <div className="listContainer">
                            <div className="listHeaders">
                                <div className="listHeader">
                                    <Text subStyle="emphasis">Name</Text>
                                </div>
                                <div className="listHeader email">
                                    <Text subStyle="emphasis">Email</Text>
                                </div>
                                <div className="listHeader initials">
                                    <Text subStyle="emphasis">Initials</Text>
                                </div>
                                <div className="listHeader">
                                    <Text subStyle="emphasis">Last login</Text>
                                </div>
                                <div className="listHeader">
                                    <Text subStyle="emphasis">Permissions</Text>
                                </div>
                                <div className="listHeader edit">
                                    <Text subStyle="emphasis">Edit</Text>
                                </div>
                            </div>

                            <div className="listDataWrapper">
                                
                                {users.map((user, index) => {

                                    return (
                                        <div key={user._id}>
                                            <div  className={user.disabled ? "disabledAccount listRowContainer" : "listRowContainer"}>
                                                
                                                <div className="listRowData">
                                                    <div className="dataWrapper">
                                                        <Text>{user.firstName} {user.lastName}</Text>
                                                    </div>
                                                </div>

                                                <div className="listRowData email">
                                                    <div className="dataWrapper">
                                                        <Text>
                                                            {user.email}
                                                        </Text>
                                                    </div>
                                                </div>

                                                <div className="listRowData initials">
                                                    <div className="dataWrapper">
                                                        <Text>
                                                            {user.initials}
                                                        </Text>
                                                    </div>
                                                </div>

                                                <div className="listRowData">
                                                    <div className="dataWrapper">
                                                        {(user.lastLoginAt && user.lastLoginAt !== null) ? (
                                                            <Text>{format(new Date(user.lastLoginAt), "HH:mm iii do MMM yyyy")}</Text>
                                                        ) : (
                                                                <Text>Never</Text>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="listRowData">
                                                    <div className="dataWrapper permissions">
                                                        <Text onClick={() => this.openPermisson(index)}>
                                                            Permissions
                                                        </Text>
                                                    </div>
                                                </div>

                                                <div className="listRowData edit">
                                                    <div className="dataWrapper">
                                                        <Text>
                                                            <Link to={"/users/" + user._id}>Edit</Link>
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={this.state.showPermission === index ? "showPermissions show" : "showPermissions hide"}>
                                                <Text subStyle="emphasis" className="header">Permissions</Text>
                                                <div>
                                                    {this.renderPermissions(user.permissions)}
                                                </div>
                                                <img onClick={() => {this.setState({showPermission: null})}} className="close" src={closeIcon} alt="close" />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>

                        <form className="addUserRow">
                            <Text as="p" subStyle="emphasis">Add User</Text>
                            <div className="wrapper">
                                <TextInput
                                    name="volvoEmail"
                                    value={this.state.volvoEmail}
                                    onChange={(e) => this.setState({ volvoEmail: e.target.value })}
                                    label="E-mail address"
                                    placeholder="xxx@volvocars.com">
                                    
                                </TextInput>
                                <Button onClick={(e) => { this.createUser(e) }}>Add</Button>
                            </div>
                        </form>
                    </>
                )}
                
                { this.state.showPermission != null ? 
                    <div onClick={() => this.setState({showPermission: null})} className="permissionsOverlay"></div> 
                : null }

            </div>
        );
    }
}

Users.contextType = BackendContext;
export default Users;
