import React from 'react';
import { Text } from 'vcc-ui';
import './styles.scss';
import LanguageFlag from '../LanguageFlag';
import LanguageCodes from '../../languageCodes.json';
import { Link } from 'react-router-dom';
import LoginRequired from '../LoginRequired';
import PermissionRequired from '../PermissionRequired';

export default function LanguageSelect({history, match, organisation, connection}) {

    if (organisation === null) {
        return null;
    }

    let user = null;
    if (connection) {
        if (connection.meta) {
            if (connection.meta.user) {
                const idp = connection.meta.user.idp;
                if (idp !== "none") {
                    user = connection.meta.user;
                }
            }
        }
    }
    
    if (user === null) {
        return (
            <div className="languageSelect">
                <LoginRequired history={history} />
            </div>
        )
    } else {
        // We are logged in, check organisation permissions
        if (!(organisation._id in user.permissions.organisations) || !user.permissions.organisations[organisation._id].localTransLangs || user.permissions.organisations[organisation._id].localTransLangs.length === 0) {
            return (
                <div className="languageSelect">
                    <PermissionRequired history={history} permissionName={organisation.name + ": Local Editor"} />
                </div>
            )
        }
    }

    const languageGroups = {}
    LanguageCodes.forEach(continent => {
        continent.languages.forEach(lang => {
            if (organisation.languages.indexOf(lang.code) !== -1) {
                // Does user have this permission?
                if (user.permissions.organisations[organisation._id].localTransLangs.indexOf(lang.code) !== -1) {
                    // Add to group
                    if (!(continent.name in languageGroups)) {
                        languageGroups[continent.name] = [];
                    }
                    languageGroups[continent.name].push(lang);
                }
            }
        })
    });

    return (
        <div className="languageSelect">
            <Text as="h2" style={{textAlign: 'center', paddingTop: '50px', fontSize: '26px'}}>
                Choose a language
            </Text>

            <div className="languageWrapper">
                {(Object.keys(languageGroups).map(continentName => (
                    <div key={continentName} className="flagsWrapper">
                        <Text as="h4" subStyle="emphasis">{continentName}</Text>
                        <ul> 
                            {(languageGroups[continentName].map(language => (
                                <li key={language.code}>
                                    <Link to={"/" + organisation.slug + "/local/" + language.code}>
                                        <LanguageFlag languageCode={language.code} name={language.name} flagImage={language.flagImage} />
                                    </Link>
                                </li>
                            )))}
                        </ul>
                    </div>
                )))}
            </div>
        </div>
    );

}
