import React from 'react';

import './styles.scss';
import { BackendContext } from '../BackendContext';

class BackendErrors extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: []
        }
    }

    componentDidMount() {
        this.statusRef = this.context.backend.listenErrors((newError) => {
            this.setState((oldState) => {
                return {
                    ...oldState,
                    errors: [...oldState.errors, newError], // Push the new error
                }
            });
        });
    }

    close(index) {
        this.setState((oldState) => {
            return {
                ...oldState,
                errors: oldState.errors.filter((_, i) => i !== index)
            }
        });
    }
  
    componentWillUnmount() {
        this.context.backend.unListenErrors(this.statusRef);
    }

    render() {
        if (this.state.errors.length > 0) {
            return (
                <>
                <div className="errorsBar">
                    {this.state.errors.filter(error => !error.removed).map((error, i) => 
                        <div key={i} className="error"><strong>{error.title}</strong> - {error.text} <div className="close"><span onClick={() => this.close(i)}>❌</span></div></div>
                    )}
                </div>
                </>
            )
        } else {
            return null;
        }
        
    }  
}

BackendErrors.contextType = BackendContext;
export default BackendErrors;
