import React, { useState } from 'react';
import Select from 'react-select';
import { Text, TabNav, TabNavItem } from 'vcc-ui';
import './styles.scss';
import VariableOverride from '../VariableOverride';

const ConfigPane = ({ checklistVersionId, currentTemplate, possibleTemplates, templateVariables, overriddenVariables, updateTemplate, setOverride, clearOverride }) => {

    const [expandedSection, setExpandedSection] = useState(0);
    const [editingVariable, setEditingVariable] = useState(null);

    const openContext = (index) => {
        setExpandedSection((oldIndex) => {
            return index;
        });
    }

    const openForEditing = (contextIndex, variableId) => {
        setEditingVariable({
            contextIndex,
            variableId,
        })
    }

    const stopEditing = () => {
        setEditingVariable(null);
    }

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '30px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
    };

    return (
        <div className="configPane">

            <Text 
                as="h4" 
                style={{ marginBottom: '10px', marginTop: '20px' }}
                subStyle="emphasis"
            >
                Template
            </Text>

            <div className="selectWrapper" style={{width: '250px'}}>
                <Select
                    onChange={(prop) => {
                        updateTemplate(prop)
                    }}
                    placeholder="Select template"
                    options={possibleTemplates}
                    styles={selectStyles}
                    value={currentTemplate}
                    menuPlacement="auto"
                    menuPosition="fixed"
                />
            </div>


            <Text 
                as="h4" 
                style={{marginBottom: '10px', marginTop: '20px'}}
                subStyle="emphasis"
            >
                Template Variables
            </Text>

            <TabNav enableLineTransition textAlign={'left'}>
                {templateVariables.map((context, i) => (
                    <TabNavItem
                        key={i}
                        onClick={() => openContext(i)}
                        isActive={expandedSection === i}
                    >
                        {context.context}
                    </TabNavItem>
                ))}
            </TabNav>

            {templateVariables.map((context, i) => (
                <div key={i}>
                    {expandedSection === i && (
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <Text as="p" subStyle="emphasis">
                                            ID
                                        </Text>
                                    </th>
                                    <th>
                                        <Text as="p" subStyle="emphasis">
                                            Default Text
                                        </Text>
                                    </th>
                                    <th>
                                        <Text as="p" subStyle="emphasis">
                                            Overridden
                                        </Text>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(context.variables).map((variableId) => {
                                    const overrideKey = context.context + "_" + variableId;
                                    return (
                                        <VariableOverride
                                            key={variableId}
                                            variableId={variableId}
                                            defaultText={context.variables[variableId]}
                                            overrideText={overriddenVariables[overrideKey]}
                                            setOverride={(...args) => {
                                                stopEditing();
                                                setOverride(overrideKey, ...args)
                                            }}
                                            clearOverride={(...args) => clearOverride(overrideKey, ...args)}
                                            openEdit={(...args) => openForEditing(i, variableId)}
                                            stopEdit={(...args) => stopEditing()}
                                            isEditing={editingVariable !== null && editingVariable.contextIndex === i && editingVariable.variableId === variableId}
                                        />
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            ))}

        </div>
    );

}

export {
    ConfigPane
};
