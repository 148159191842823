import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { BackendContext } from '../BackendContext';
//import UserRegistrations from '../UserRegistrations';
import { format } from 'date-fns';
import { AddChecklistBar } from '../AddChecklistBar';
import { Text } from 'vcc-ui';
import UserRegistrations from '../UserRegistrations';
import LoginRequired from '../LoginRequired';
import PermissionRequired from '../PermissionRequired';

class ChecklistList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            master: null,
            connectionStatus: null,
        };
    }

    getCurrentlyLoggedInUserFromConnectionStatus() {
        if (this.state.connectionStatus) {
            if (this.state.connectionStatus.meta) {
                if (this.state.connectionStatus.meta.user) {
                    const idp = this.state.connectionStatus.meta.user.idp;
                    if (idp !== "none") {
                        return this.state.connectionStatus.meta.user;
                    }
                }
            }
        }
        return null;
    }

    getOrganisationSlug() {
        let slug = null;
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.org) {
                    slug = this.props.match.params.org;
                }
            }
        }
        return slug;
    }
    
    componentDidMount() {
        const organisationSlug = this.getOrganisationSlug();
        // Tell the backend about this component starting up
        this.backendRef = this.context.backend.register("masterChecklists", {
            organisationSlug,
        }, (reducerCallback) => {
            // The reducerCallback takes in the old state and returns the new state
            this.setState((oldState) => {
                const oldCLL = oldState.master;
                const newCLL = reducerCallback(oldCLL);
                if (newCLL) {
                    // Special case where the reducer returns null/undefined, don't do anything
                    return {
                        ...oldState,
                        master: newCLL,
                    }
                }
            });
        }, (newConnectionStatus) => { // Object of keys status (string), tokenExpiry (date)
            this.setState((oldState) => {
                return {
                    ...oldState,
                    connectionStatus: newConnectionStatus,
                }
            });
        });
    }
  
    componentWillUnmount() {
        this.context.backend.unRegisterData(this.backendRef);
    }

    getMyConnectionId() {
        if (this.state.connectionStatus === null || !this.state.connectionStatus.meta || this.state.connectionStatus.meta === null) {
            return null;
        } else {
            return this.state.connectionStatus.meta.connectionId;
        }
    }

    getChecklists() {
        if (this.state.master === null || !this.state.master.checklists) {
            return [];
        } else {
            // Merge the checklist versions in to each checklist
            const checklists = [];
            Object.values(this.state.master.checklists).forEach(cl => {
                let published = null;
                if (cl.publishedVersion && cl.publishedVersion in this.state.master.checklistVersions) {
                    published = this.state.master.checklistVersions[cl.publishedVersion];
                    // Also the lastUpdatedBy on the editing and published versions
                    if (published.lastUpdatedBy && published.lastUpdatedBy in this.state.master.users) {
                        published.lastUpdatedUser = this.state.master.users[published.lastUpdatedBy];
                    } else {
                        published.lastUpdatedUser = null;
                    }
                }
                let editing = null;
                if (cl.editingVersion && cl.editingVersion in this.state.master.checklistVersions) {
                    editing = this.state.master.checklistVersions[cl.editingVersion];
                    // Also the lastUpdatedBy on the editing and published versions
                    if (editing.lastUpdatedBy && editing.lastUpdatedBy in this.state.master.users) {
                        editing.lastUpdatedUser = this.state.master.users[editing.lastUpdatedBy];
                    } else {
                        editing.lastUpdatedUser = null;
                    }
                }
                // Also the createdBy and lastUpdatedBy
                let createdUser = null;
                if (cl.createdBy && cl.createdBy in this.state.master.users) {
                    createdUser = this.state.master.users[cl.createdBy];
                }
                let lastUpdatedUser = null;
                if (cl.lastUpdatedBy && cl.lastUpdatedBy in this.state.master.users) {
                    lastUpdatedUser = this.state.master.users[cl.lastUpdatedBy];
                }
                


                checklists.push({
                    ...cl,
                    published,
                    editing,
                    createdUser,
                    lastUpdatedUser,
                });
            });
            return checklists;
        }
    }

    getUserOnThisPage() {
        if (this.state.master === null || !this.state.master._regs) {
            return {};
        } else {
            // Find the sockets that have at least one view matching this page
            const list = {};
            Object.keys(this.state.master._regs).map(socketId => {
                const reg = this.state.master._regs[socketId];
                let hasAppropriateView = false;
                Object.keys(reg.views).forEach(viewId => {
                    const view = reg.views[viewId];
                    if (view.listenType === "masterChecklists") {
                        hasAppropriateView = true;
                    }
                })
                if (hasAppropriateView) {
                    list[socketId] = reg;
                }
                return null;
            });
            return list;
        }
    }

    getUsersEditingChecklistVersion(clvId) {
        if (this.state.master === null || !this.state.master._regs) {
            return {};
        } else {
            // Find the sockets that have at least one view matching this page
            const list = {};
            Object.keys(this.state.master._regs).map(socketId => {
                const reg = this.state.master._regs[socketId];
                let hasAppropriateView = false;
                Object.keys(reg.views).forEach(viewId => {
                    const view = reg.views[viewId];
                    if (view.listenType === "checklistVersion" && view.listenParams.checklistVersionId === clvId) {
                        hasAppropriateView = true;
                    }
                })
                if (hasAppropriateView) {
                    list[socketId] = reg;
                }
                return null;
            });
            return list;
        }
    }

    getCurrentIdents() {
        return this.getChecklists().map(cl => cl.shortCode);
    }

    render() {
        const checklists = this.getChecklists();
        // console.log("CHECKLISTS", checklists);

        //const usersOnThisPage = this.getUserOnThisPage();
        
        const myConnectionId = this.getMyConnectionId();
        const currentIdents = this.getCurrentIdents();
        const organisationSlug = this.getOrganisationSlug();
        const organisation = this.props.organisation;
        
        const user = this.getCurrentlyLoggedInUserFromConnectionStatus();

        // Note: Organisation should always be set on a page with org slug in the url
        if (organisation === null) {
            return null;
        }
        
        if (user === null) {
            return (
                <div className="users">
                    <LoginRequired history={this.props.history} />
                </div>
            )
        } else {
            // We are logged in, check organisation permissions
            if (user.permissions.organisations[organisation._id].isMasterEditor !== true) {
                return (
                    <div className="users">
                        <PermissionRequired history={this.props.history} permissionName={organisation.name + ": Master Editor"} />
                    </div>
                )
            }
        }

        return (
            <div className="checklistList">

                <AddChecklistBar currentIdents={currentIdents} addChecklist={(name, ident) => this.context.backend.addChecklist(organisationSlug, name, ident)} />

                <div className="listContainer">
                    <div className="listHeaders">
                        <div className="listHeader">
                            <Text subStyle="emphasis">ID</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Name</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Created</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Updated</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Published</Text>
                        </div>
                        <div className="listHeader">
                            <Text subStyle="emphasis">Edit</Text>
                        </div>
                    </div>

                    <div className="listDataWrapper">
                        {checklists.map((item, index) => {
                            const usersEditingChecklistVersion = this.getUsersEditingChecklistVersion(item.editing._id);
                            return (
                                <div className="listRowContainer" key={item._id}>
                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            <Text>{item.shortCode}</Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            <Text>{item.name}</Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            {item.createdUser !== null && (
                                                <Text>
                                                    {item.createdUser.firstName} {item.createdUser.lastName} {item.createdUser.email}
                                                    <br />
                                                </Text>
                                            )}
                                            <Text>{format(new Date(item.createdAt), "HH:mm iii do MMM yyyy")}</Text>
                                        </div>
    
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            {item.editing.lastUpdatedUser !== null && (
                                                <Text>
                                                    {item.editing.lastUpdatedUser.firstName} {item.editing.lastUpdatedUser.lastName} {item.editing.lastUpdatedUser.email}
                                                    <br />
                                                </Text>
                                            )}

                                            <Text>
                                                {format(new Date(item.editing.lastUpdatedAt), "HH:mm iii do MMM yyyy")}
                                            </Text>
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            {item.published ? (
                                                <Link className="button" to={`/${organisationSlug}/master/edit/${item.published._id}`}>
                                                    <Text>View V{item.published.versionNumber}</Text>
                                                </Link>
                                            ) : (
                                                <Text>Never</Text>
                                            )}
                                            
                                        </div>
                                    </div>

                                    <div className="listRowData">
                                        <div className="dataWrapper">
                                            
                                            <Link className="button" to={`/${organisationSlug}/master/edit/${item.editing._id}`}>
                                                <Text>Edit V{item.editing.versionNumber}</Text>
                                            </Link>
                                            
                                            <div className="checkListUsersEdit">
                                                <UserRegistrations myConnectionId={myConnectionId} regs={usersEditingChecklistVersion} alignRight={false} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

}
ChecklistList.contextType = BackendContext;
export default ChecklistList;

