import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'vcc-ui';
import UserInfo from '../UserInfo';
import userIcon from '../../assets/user.svg';
import logout from '../../assets/logout.svg';
import login from '../../assets/logout.svg';
import hamburgerIcon from '../../assets/menu.svg';
import './styles.scss';

const Menu = ({organisation, organisationSlug, connection, clearLoginToken, history}) => {
    const pathname = window.location.pathname;

    const [profileOpen, setProfileOpen] = useState(false);
    const [active, setActive] = useState('');
    const [mobileNav, setMobileNav] = useState(false);

    // console.log("organisationSlug", organisationSlug);

    // console.log("organisation", organisation);

    useEffect(() => {
        if (/^\/[^/]+\/master/.test(pathname)) {
            setActive("master");

        } else if (/^\/[^/]+\/local/.test(pathname)) {
            setActive("local");

        } else if (/^\/[^/]+\/downloads/.test(pathname)) {
            setActive("downloads");

        } else if (/^\/users/.test(pathname)) {
            setActive("users");

        } else if (/^\/login/.test(pathname)) {
            setActive("login");

        } else if (/^\/[^/]+/.test(pathname)) {
            setActive("start");
            
        } else if (pathname === "/") {
            setActive("chooseOrg");
        }
    }, [pathname]);

    const logOut = () => {
        clearLoginToken(false)
        setProfileOpen(false);
        history.push("/login");
    }

    const logIn = () => {
        setProfileOpen(false);

        // Get the current path and shove in to the redirectUrl param for the login page
        let loginUrl = "/login";
        const pathname = window.location.pathname;
        if (pathname !== "/login") {
            loginUrl += "?redirectUrl=" + encodeURIComponent(pathname);
        }

        history.push(loginUrl);
    }

    if (typeof organisationSlug === "undefined") {
        return null;
    }

    let user = null;
    if (connection) {
        if (connection.meta) {
            if (connection.meta.user) {
                const idp = connection.meta.user.idp;
                if (idp !== "none") {
                    user = connection.meta.user;
                }
            }
        }
    }
    
    let showMasterChecklists = false;
    let showLocalChecklists = false;
    let showUsers = false;
    if (user !== null && organisation !== null) {
        if (user.permissions && user.permissions.organisations && organisation._id in user.permissions.organisations) {
            if (user.permissions.organisations[organisation._id].isMasterEditor === true) {
                showMasterChecklists = true;
            }
            if ((organisation._id in user.permissions.organisations) && user.permissions.organisations[organisation._id].localTransLangs && user.permissions.organisations[organisation._id].localTransLangs.length > 0) {
                showLocalChecklists = true;
            }
        }
        if (user.permissions && user.permissions.isUserAdmin === true) {
            showUsers = true;
        }
    }

    // After go live, swap this around
    const isStaging = window.location.hostname === "stage-usedcaradmin.volvocars.biz";
    // const isStaging = window.location.hostname === "localhost";


    return (
        <div className="menuWrapper">
            <div className={isStaging ? "menuInnerwrapper isStaging": "menuInnerwrapper"}>

                <Text as="h2" variant="cook">Checklists App {isStaging && ("STAGING ENVIRONMENT")} {organisation && (
                    <>
                    - {organisation.name} <button className="small" onClick={() => history.push("/")}>change</button>
                    </>
                )}</Text>
                
                <div className="hamburgerNav">
                    <img src={hamburgerIcon} alt="hamburger" onClick={() => {setMobileNav(!mobileNav)}} />
                </div>

                <div className={mobileNav ? "menuItems mobileNav" : "menuItems"}>
                    { organisationSlug === null ? (
                        <>
                            <div className="menuItem">
                                <Text subStyle="emphasis">
                                    <Link className={active === "start" ? "active" : null} to={'/'} onClick={() => setMobileNav(false)}>Choose Organisation</Link>
                                </Text>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="menuItem">
                                <Text subStyle="emphasis">
                                    <Link className={active === "start" ? "active" : null} to={'/' + organisationSlug} onClick={() => setMobileNav(false)}>Start</Link>
                                </Text>
                            </div>
                            {showMasterChecklists && (
                                <div className="menuItem">
                                    <Text subStyle="emphasis">
                                        <Link className={active === "master" ? "active" : null} to={'/' + organisationSlug + '/master/list'} onClick={() => setMobileNav(false)}>Master Checklists</Link>
                                    </Text>
                                </div>
                            )}
                            {showLocalChecklists && (
                                <div className="menuItem">
                                    <Text subStyle="emphasis">
                                        <Link className={active === "local" ? "active" : null} to={'/' + organisationSlug + '/local'} onClick={() => setMobileNav(false)}>Local Checklists</Link>
                                    </Text>
                                </div>
                            )}
                            
                            
                            <div className="menuItem">
                                <Text subStyle="emphasis">
                                    <Link className={active === "downloads" ? "active" : null} to={'/' + organisationSlug + '/downloads'} onClick={() => setMobileNav(false)}>Downloads</Link>
                                </Text>
                            </div>
                        </>
                    )}
                    {showUsers && (
                        <div className="menuItem">
                            <Text subStyle="emphasis">
                                <Link className={active === "users" ? "active" : null} to={`/users`} onClick={() => setMobileNav(false)}>Users</Link>
                            </Text>
                        </div>
                    )}
                    

                    <div className="divider"></div>

                    { /* Don't show the user if the user is not logged in */ }
                    { (connection !== null && connection.meta && connection.meta.user && connection.meta.user.uniqueIdent !== "nobody") ? (
                        <>
                            <div className="menuItem user" onClick={() => setProfileOpen(!profileOpen)}>
                                <img src={userIcon} alt="user" />
                                <div className="mobileNavUser">
                                    <UserInfo connection={connection} />
                                </div>
                            </div>
                            <div className="logoutMobileNav" onClick={() => logOut()}>
                                <img src={logout} alt="logout" />
                                <Text subStyle="emphasis">Logout</Text>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="menuItem">
                                <Text subStyle="emphasis">
                                    <div className={active === "login" ? "hyperlink active" : "hyperlink"} onClick={() => { setMobileNav(false); logIn(); }}>Login</div>
                                </Text>
                            </div>
                        </>
                    )}
                    
                </div>

                {profileOpen ?
                    <div className="profile">
                        
                        { (connection !== null && connection.meta && connection.meta.user && connection.meta.user.uniqueIdent !== "nobody") ? (
                            <div className="profileInner">
                                <UserInfo connection={connection} />
                        
                                <div className="logout" onClick={() => logOut()}>
                                    <img src={logout} alt="logout" />
                                    <Text subStyle="emphasis">Logout</Text>
                                </div>
                            </div>  
                        ) : (
                            <div className="profileInner">
                                <div className="logout" onClick={() => logIn()}>
                                    <img src={login} alt="login" />
                                    <Text subStyle="emphasis">Login</Text>
                                </div>
                            </div> 
                        )}
                    
                    </div>
                : null}

                {profileOpen ? <div className="overlay" onClick={() => setProfileOpen(false)}></div> : null}

            </div>

        </div>
    )
}

export {
    Menu
}
