import React from 'react';
import { Text } from 'vcc-ui';
import './styles.scss';

export default function OrganisationChoice({orgs, chooseOrganisation}) {

    const orgsList = Object.values(orgs);

    return (
        <div className="organisationChoice">
            {orgsList.map(org => (
                <div className="org" key={org._id} onClick={(e) => chooseOrganisation(org.slug)}>
                    <img src={"/images/" + org.image} alt={org.name} />
                    {org.displayNameUnderImage && (
                        <Text as="h3">{org.name}</Text>
                    )}
                </div>
            ))}
            {orgsList.length === 0 && (
                <div>Loading organisations...</div>
            )}
        </div>
    );

}
