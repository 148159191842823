import React from 'react';
import { getEndpoint } from "../../lib/endpoint";
import Backend from '../../lib/backend';

const backendEndpoint = getEndpoint();

// Store it at window._fbiBackend so we can shut and old one down!
if (window._fbiBackend) {
    window._fbiBackend.shutdown();
    console.log("Shutdown an OLD backend");
}
console.log("Instantiating new backend");
const backend = new Backend(backendEndpoint);
window._fbiBackend = backend;

// TODO, we connect as soon as we know the login token
// backend.setUserToken("1234");
// backend.connect();

export const BackendContext = React.createContext({
    backend
});