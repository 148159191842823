import ListenClient from "./listenClient";

export default class Backend {

    constructor(backendEndpoint) {
        this.lc = new ListenClient(backendEndpoint);
        this.nextViewId = 1;
        this.immediateSetStates = {};
        this.errorRegistrations = {};

        this.lc.listenErrors((title, text) => {
            this.reportError(title, text);
        })
    }

    setUserToken(token) {
        this.lc.setUserToken(token);
    }

    reset() {
        this.lc.reset();
    }

    listenErrors(callback) {
        const thisId = "errors-" + this.nextViewId;
        this.nextViewId++;
        
        const regObj = {
            id: thisId,
            listener: callback,
        };
        this.errorRegistrations[thisId] = regObj;

        return thisId;
    }
    unListenErrors(thisId) {
        delete this.errorRegistrations[thisId];
    }
    reportError(title, text) {
        const error = {
            title,
            text
        }
        Object.keys(this.errorRegistrations).forEach(listenId => {
            this.errorRegistrations[listenId].listener.apply(this, [error]);
        });
    }

    listenConnectionStatus(callback) {
        const thisId = "connect-" + this.nextViewId;
        this.nextViewId++;
        this.lc.listenConnection(thisId, callback);
        return thisId;
    }
    unListenConnectionStatus(id) {
        this.lc.unlistenConnection(id);
    }

    register(type, params, setViewState, setConnectionState) {
        // console.log("REG PARAMS 1", params);
        const thisView = "view-" + this.nextViewId;
        this.nextViewId++;

        this.immediateSetStates[thisView] = setViewState; // This is so we can access the setState immediately to update the state now (E.g. On a row movement)

        this.lc.listen(thisView, type, params, (type, updateEvent) => {
            // TODO Apply these updates using the local setState method with reducer callback which returns the new view
            if (type === "setView") {
                // With a full update, we don't really care about the old state, just replace
                setViewState((oldState) => {
                    return {
                        ...oldState, 
                        ...updateEvent.value
                    };
                });
                // console.log("View updated to", updateEvent.value);

            } else if (type === "viewFieldsSet") {

                setViewState((oldState) => {
                    // For each of the updates, apply these in a non mutating way to the old state
                    const newState = this.applyFieldUpdates(oldState, updateEvent.updates);
                    // console.log("NEW STATE", newState);
                    return newState;
                })


                // console.log("View fields updates", updateEvent.updates);

            } else if (type === "reportRegs") {

                // We put regs in the _regs field for now.
                // Full update in regs field
                setViewState((oldState) => {
                    if (oldState === null) {
                        oldState = {};
                    }
                    return {
                        ...oldState,
                        _regs: updateEvent.regs,
                    }
                });

            } else {
                console.warn("TODO Unknown type: " + type);

            }
        });

        if (setConnectionState) {
            this.lc.listenConnection(thisView, (newConnectionStatusObj) => {
                // console.log("NEW CONNECTION STATUS", newConnectionStatusObj);
                setConnectionState(newConnectionStatusObj);
            });
        }

        return thisView;
    }

    applyFieldUpdates(oldState, updates) {
        const newState = {...oldState}
        updates.forEach(update => {
            if (update.path.length === 1) {
                if (update.value === "<D>") {
                    delete newState[update.path[0]];
                } else {
                    newState[update.path[0]] = update.value;
                }
            } else {
                // We need to recurse deeper 1 level
                const firstPathItem = update.path.shift();
                newState[firstPathItem] = this.applyFieldUpdates(newState[firstPathItem], [update]);
            }
        });
        return newState;
    }
    
    async unRegisterData(ref) {
        // console.log("UnRegistered", ref);
        this.lc.unlistenConnection(ref);
        await this.lc.unlisten(ref);
        
    }

    connect() {
        this.lc.connect();
    }

    shutdown() {
        // console.log("Backend Shutdown");
        this.lc.shutdown();
    }

    async emitAction(actionType, actionParams) {
        try {
            const result = await this.lc.emitAction(actionType, actionParams);
            return result;
        } catch(e) {
            console.warn(e);
            console.warn("Sending error: ", e);
            this.reportError("Error", e.message);
        }
    }

    // Row Items

    async addRowItem(checklistVersionId, rowText, rowType, insertBefore) {
        // console.log("Adding", rowText, rowType, insertBefore);
        return await this.emitAction("action", {
            type: "addRowItem",
            checklistVersionId,
            rowType,
            rowText,
            insertBefore,
        });
    }

    reorderRowItems(thisView, checklistVersionId, rowId, newPos) {
        // console.log("reorderSelektData", rowId, newPos);
        // New action to backend code
        // First update OUR state and work out whether the item has REALLY moved
        const setState = this.immediateSetStates[thisView];

        setState((oldState) => {
            const newOrder = [...oldState.rowItemsOrder];
            const oldPos = newOrder.indexOf(rowId);
            // Was the draggable row id found?
            if (oldPos !== -1) {
                // Is the row moving to a different position?
                if (oldPos !== newPos) {
                    newOrder.splice(oldPos, 1);
                    newOrder.splice(newPos, 0, rowId);

                    // Send to the backend NOW
                    // Since we have already updated our state on this end, it MUST succeed.
                    // Otherwise a refresh will be required to reset everything

                    this.emitAction("action", {
                        type: "reorderRowItems",
                        checklistVersionId,
                        rowId,
                        newPos,
                    });

                    // console.log("NEW ORDER IS", newOrder);
                    return {
                        ...oldState,
                        rowItemsOrder: newOrder
                    }
                }
            }

            return oldState; // No change to the state
        });
    }

    async deleteRowItem(checklistVersionId, rowItemId) {
        return await this.emitAction("action", {
            type: "deleteRowItem",
            checklistVersionId,
            rowItemId,
        });
    }

    async updateRowItem(checklistVersionId, rowItemId, updateType, value) {
        if (updateType === "text") {
            return await this.updateRowItemText(checklistVersionId, rowItemId, value);
        } else if (updateType === "type") {
            return await this.updateRowItemType(checklistVersionId, rowItemId, value);
        }
    }
    async updateRowItemText(checklistVersionId, rowItemId, itemText) {
        return await this.emitAction("action", {
            type: "updateRowItemText",
            checklistVersionId,
            rowItemId,
            itemText,
        });
    }

    async updateRowItemType(checklistVersionId, rowItemId, rowType) {
        return await this.emitAction("action", {
            type: "updateRowItemType",
            checklistVersionId,
            rowItemId,
            rowType,
        });
    }

    // Notes

    async addNote(checklistVersionId, noteText, insertBefore) {
        // console.log("Adding Note", noteText, insertBefore);
        return await this.emitAction("action", {
            type: "addNote",
            checklistVersionId,
            noteText,
            insertBefore,
        });
    }

    reorderNotes(thisView, checklistVersionId, noteId, newPos) {
        // console.log("reorderNotes", noteId, newPos);
        // New action to backend code
        // First update OUR state and work out whether the item has REALLY moved
        const setState = this.immediateSetStates[thisView];

        setState((oldState) => {
            const newOrder = [...oldState.notesOrder];
            const oldPos = newOrder.indexOf(noteId);
            // Was the draggable row id found?
            if (oldPos !== -1) {
                // Is the row moving to a different position?
                if (oldPos !== newPos) {
                    newOrder.splice(oldPos, 1);
                    newOrder.splice(newPos, 0, noteId);

                    // Send to the backend NOW
                    // Since we have already updated our state on this end, it MUST succeed.
                    // Otherwise a refresh will be required to reset everything

                    this.emitAction("action", {
                        type: "reorderNotes",
                        checklistVersionId,
                        noteId,
                        newPos,
                    });

                    // console.log("NEW ORDER IS", newOrder);
                    return {
                        ...oldState,
                        notesOrder: newOrder
                    }
                }
            }

            return oldState; // No change to the state
        });
    }

    async deleteNote(checklistVersionId, noteId) {
        return await this.emitAction("action", {
            type: "deleteNote",
            checklistVersionId,
            noteId,
        });
    }

    async updateNoteText(checklistVersionId, noteId, noteText) {
        return await this.emitAction("action", {
            type: "updateNoteText",
            checklistVersionId,
            noteId,
            noteText,
        });
    }

    async updateTemplate(checklistVersionId, item) {
        return await this.emitAction("action", {
            type: "updateTemplate",
            checklistVersionId,
            templateId: item.value,
        });
    }
    async setVariableOverride(checklistVersionId, variableKey, variableValue) {
        return await this.emitAction("action", {
            type: "setVariableOverride",
            checklistVersionId,
            variableKey,
            variableValue,
        });
    }
    async clearVariableOverride(checklistVersionId, variableKey) {
        return await this.emitAction("action", {
            type: "clearVariableOverride",
            checklistVersionId,
            variableKey,
        });
    }

    async addChecklist(organisationSlug, name, ident) {
        return await this.emitAction("action", {
            type: "addChecklist",
            organisationSlug,
            name,
            ident,
        });
    }

    async publishChecklistVersion(checklistVersionId) {
        return await this.emitAction("action", {
            type: "publishChecklistVersion",
            checklistVersionId,
        });
    }

    async setEnglishTranslation(checklistId, languageCode, translationType, translationId, hashedMaster, masterText) {
        // console.log("Set english translation", checklistId, languageCode, translationType, translationId, hashedMaster, masterText);
        return await this.emitAction("action", {
            type: "setTranslation",
            checklistId, 
            languageCode, 
            translationType, 
            translationId, 
            hashedMaster, 
            masterText,
            updateType: "copyEnglish",
        });
    }
    async doGoogleTranslate(checklistId, languageCode, translationType, translationId, hashedMaster, masterText) {
        // console.log("Do google translation", checklistId, languageCode, translationType, translationId, hashedMaster, masterText);
        return await this.emitAction("action", {
            type: "setTranslation",
            checklistId, 
            languageCode, 
            translationType, 
            translationId, 
            hashedMaster, 
            masterText,
            updateType: "googleTranslate",
        });
    }
    async setTranslation(checklistId, languageCode, translationType, translationId, hashedMaster, masterText, localText) {
        // console.log("Set Translation", checklistId, languageCode, translationType, translationId, hashedMaster, masterText, localText);
        return await this.emitAction("action", {
            type: "setTranslation",
            checklistId, 
            languageCode, 
            translationType, 
            translationId, 
            hashedMaster, 
            masterText,
            localText,
            updateType: "manual",
        });
    }
    async clearTranslation(checklistId, languageCode, translationType, translationId, hashedMaster) {
        // console.log("Clear Translation", checklistId, languageCode, translationType, translationId, hashedMaster);
        return await this.emitAction("action", {
            type: "clearTranslation",
            checklistId, 
            languageCode, 
            translationType, 
            translationId, 
            hashedMaster, 
        });
    }
    async authorChecklist(checklistId, languageCode, checklistVersionId) {
        return await this.emitAction("action", {
            type: "authorChecklist",
            checklistId, 
            languageCode, 
            checklistVersionId, 
        });
    }
    async updateUserOrgPermission(userId, organisationId, permissionField, permissionKey, permissionValue) {
        return await this.emitAction("action", {
            type: "updateUserOrgPermission",
            userId, 
            organisationId,
            permissionField, 
            permissionKey, 
            permissionValue,
        });
    }
    async disableUser(userId) {
        return await this.emitAction("action", {
            type: "disableUser",
            userId,
        });
    }
    async enableUser(userId) {
        return await this.emitAction("action", {
            type: "enableUser",
            userId,
        });
    }
    async createUser(volvoEmail) {
        return await this.emitAction("action", {
            type: "createUser",
            volvoEmail,
        });
    }


    // Local rows
    async addLocalRowItem(checklistId, languageCode, rowText, rowType, insertBefore) {
        console.log("Adding", rowText, rowType, insertBefore);
        
        return await this.emitAction("action", {
            type: "addLocalRowItem",
            checklistId,
            languageCode,
            rowType,
            rowText,
            insertBefore,
        });
    }

    reorderLocalRowItems(thisView, checklistId, languageCode, rowId, newPos) {
        console.log("reorderSelektData", rowId, newPos);
        // New action to backend code
        // First update OUR state and work out whether the item has REALLY moved
        
        const setState = this.immediateSetStates[thisView];

        setState((oldState) => {
            const newOrder = [...oldState.checklistTranslation.localRowItemsOrder];
            const oldPos = newOrder.indexOf(rowId);
            // Was the draggable row id found?
            if (oldPos !== -1) {
                // Is the row moving to a different position?
                if (oldPos !== newPos) {
                    newOrder.splice(oldPos, 1);
                    newOrder.splice(newPos, 0, rowId);

                    // Send to the backend NOW
                    // Since we have already updated our state on this end, it MUST succeed.
                    // Otherwise a refresh will be required to reset everything

                    this.emitAction("action", {
                        type: "reorderLocalRowItems",
                        checklistId,
                        languageCode,
                        rowId,
                        newPos,
                    });

                    // console.log("NEW ORDER IS", newOrder);
                    return {
                        ...oldState,
                        checklistTranslation : {
                            ...oldState.checklistTranslation,
                            localRowItemsOrder: newOrder
                        }
                    }
                }
            }

            return oldState; // No change to the state
        });
        
    }

    async deleteLocalRowItem(checklistId, languageCode, rowItemId) {
        console.log("Delete local row item");
        
        return await this.emitAction("action", {
            type: "deleteLocalRowItem",
            checklistId, 
            languageCode,
            rowItemId,
        });
    }

    async updateLocalRowItem(checklistId, languageCode, rowItemId, updateType, value) {
        if (updateType === "text") {
            return await this.updateLocalRowItemText(checklistId, languageCode, rowItemId, value);
        } else if (updateType === "type") {
            return await this.updateLocalRowItemType(checklistId, languageCode, rowItemId, value);
        }
    }
    async updateLocalRowItemText(checklistId, languageCode, rowItemId, itemText) {
        console.log("Updating local row text", rowItemId, itemText);
        
        return await this.emitAction("action", {
            type: "updateLocalRowItemText",
            checklistId, 
            languageCode,
            rowItemId,
            itemText,
        });
    }

    async updateLocalRowItemType(checklistId, languageCode, rowItemId, rowType) {
        console.log("Updating local row type", rowItemId, rowType);
        
        return await this.emitAction("action", {
            type: "updateLocalRowItemType",
            checklistId, 
            languageCode,
            rowItemId,
            rowType,
        });
        
    }
}
