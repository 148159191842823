import React, { useState, useRef } from 'react';
import { Text } from 'vcc-ui';
import EdiText from 'react-editext';
import Select from 'react-select';
import categoryData from '../../categoryData.json'
import deleteIcon from '../../assets/delete.svg';
import menuIcon from '../../assets/menuicon.svg';
import editIcon from '../../assets/editicon.svg';
import closeIcon from '../../assets/close.svg';
import insertIcon from '../../assets/insert.svg';
import './styles.scss';

const ListItem = ({ listType, removeData, updateText, updateType, item, isRowSelected, selectedCellCol, triggerEditText, triggerEditType, stopEditing, editingText, editingType, insertRow, disableDrag }) => {

    const selectRef = useRef(null)
    const selectOptions = categoryData;

    const [editMenu, setEditMenu] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '30px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
    };
    
    const resetStates = () => {
        setEditMenu(false);
    }

    const editText = (val) => {
        resetStates();
        triggerEditText(val);
    };

    const editType = () => {
        resetStates();
        triggerEditType();
    }

    const focusSelect = (el) => {
        selectRef.current = el;

        if (selectRef.current) {
            selectRef.current.select.focus()
        }
    }

    const reportStoppedEditing = (delay) => {
        if (delay > 0) {
            setTimeout(() => {
                stopEditing();
            }, delay);
        } else {
            stopEditing();
        }
    }

    const onSelectBlur = () => {
        reportStoppedEditing(1);
    }

    const onSelectChange = (prop) => {
        updateType(prop.value);
        reportStoppedEditing(1);
    }

    const onSaveText = (val) => {
        updateText(val);
        // reportStoppedEditing(0);
    }

    const deletePrompt = () => {
        setShowPrompt(true);
        setEditMenu(false);
    }

    const valueCellClasses = ['dataCell', 'dataCellValue', listType];
    const typeCellClasses = ['dataCell', 'dataCellType'];
    if (editingText) {
        valueCellClasses.push("editing");
    }
    if (editingType) {
        typeCellClasses.push("editing");
    }

    if (isRowSelected) {
        if (selectedCellCol === 0) {
            valueCellClasses.push("selected");
        } else if (selectedCellCol === 1) {
            typeCellClasses.push("selected");
        }
    }

    const getOptionByType = (type) => {
        const appropriate = selectOptions.filter((o) => {
            return o.value === type;
        });
        if (appropriate.length === 1) {
            return appropriate[0];
        } else {
            return null;
        }
    }

    const getOptionLabelByType = (type) => {
        const option = getOptionByType(type);
        if (option === null) {
            return "<Not set>";
        }
        return option.label;
    }

    const closePrompt = () => {
        disableDrag(false);
        setShowPrompt(false);
    }
    
    return (
        
        <>
            <div className={valueCellClasses.join(" ") } onClick={() => {
                if ((item.type === "tyreTable" || item.type === "comments")) {
                    return editText({editable: false})
                } else {
                    return editText({ editable: true })
                }
            }} >
                
                {(item.type !== "tyreTable" && item.type !== "comments") ?
                    <EdiText
                        type='text' // Note: submitOnEnter does not work if this is textarea so the enter key will stop editing without saving.
                        onSave={(val) => { onSaveText(val); }}
                        onCancel={() => { stopEditing(); }}

                        cancelOnEscape
                        submitOnEnter
                        submitOnUnfocus

                        editing={editingText}
                        value={item.text}
                        saveButtonClassName="custom-save-button"
                        cancelButtonClassName="custom-cancel-button"
                        inputProps={{
                            // className: 'textarea',
                            style: {
                                outline: 'none'
                            },
                            rows: 1,
                            onBlur: () => {/* console.log("On Blur: Should be: " + item.text + " is that what we can see?") */ }
                        }}
                        viewProps={{
                            className: 'valueText'
                        }}
                    />
                : null }
                
            </div>

            {listType === "rowItems" && (
                <div className={typeCellClasses.join(" ")}>
                    { !editingType
                        ? <Text
                            className="valueType"
                            onClick={() => {
                                return editType()
                            }}
                        >
                            {getOptionLabelByType(item.type)}
                        </Text>

                        : <div className="selectWrapper">
                            <Select
                                ref={(el) => { focusSelect(el) }}
                                placeholder="Select category"
                                options={selectOptions}
                                onChange={(prop) => onSelectChange(prop)}
                                onBlur={() => onSelectBlur()}
                                openMenuOnFocus={true}
                                styles={selectStyles}
                                value={getOptionByType(item.type)}
                            />
                        </div>
                    }
                </div>
            )}

            <div className="dataCell dataCellMenu">
                <img onClick={() => setEditMenu(!editMenu)} className="menuIcon" src={menuIcon} alt="" />

                <div className={editMenu ? "editMenu open" : "editMenu"}>
                    <div className="editMenuWrapper">

                        <div className="itemWrapper">
                            <div onClick={() => { insertRow(); setEditMenu(false)}} className="insertRow">
                                <img src={insertIcon} alt="insert" />
                                <p>Insert row</p>
                            </div>
                        </div>

                        <hr />

                        {item.type !== "tyreTable" && item.type !== "comments" && (
                            <>
                                <div className="itemWrapper">
                                    <div onClick={() => editText({ editable: true })} className="editItem">
                                        <img src={editIcon} alt="edit" />
                                        <p>Edit</p>
                                    </div>
                                </div>

                                <hr />
                            </>
                        )}
                        

                        <div className="itemWrapper">
                            <div onClick={() => { deletePrompt(); disableDrag(true); }} className="deleteItem">
                                <img src={deleteIcon} alt="delete" />
                                <p>Delete</p>
                            </div>
                        </div>

                    </div>
                </div>

                { editMenu
                    ? <div onClick={() => setEditMenu(false)} className="overlay"></div>
                    : null
                }
            </div>


            { showPrompt ?
                <>
                    <div className="deletePrompt">
                        <div className="close" onClick={() => closePrompt()}>
                            <img src={closeIcon} alt="close" />
                        </div>
                        <Text className="header">
                            Delete?
                        </Text>
                        <Text style={{ paddingBottom: "20px" }}>Are you sure you want to delete {item.text.length > 1 ? <strong>"{item.text}"</strong> : 'this row'}?</Text>
                        <button className="noButton" onClick={() => closePrompt()}>
                            <Text>Cancel</Text>
                        </button>
                        <button className="yesButton" onClick={() => { 
                            closePrompt();
                            return removeData();
                        }}>
                            <Text>Yes</Text>
                        </button>
                    </div>

                </>
            : null }

            <div className={showPrompt ? "promptOverlay show" : "promptOverlay hide"} onClick={() => closePrompt()}></div>

        </>
    )  
}

export {
    ListItem
}
