import React from 'react';
import { getEndpoint } from '../../lib/endpoint';
import styles from './styles.module.scss';

const PreviewPane = ({ checklistVersionId, highlightId, checklistId, languageCode }) => {

    const backendEndpoint = getEndpoint();
    
    const fetchUrl = () => {
        let url = "";
        if (checklistVersionId) {
            url = backendEndpoint + "/generate/html/" + checklistVersionId + "?ts=" + (new Date()).getTime();
        }
        if (highlightId) {
            url += "&highlight=" + highlightId;
        }
        if (checklistId && languageCode) {
            url = backendEndpoint + "/generate/html/local/" + languageCode + "/" + checklistId + "?ts=" + (new Date()).getTime();
        }
        return url;
    }

    return (
        <div className={styles.previewPane}>
            <iframe title="preview" style={{width: "100%", height: "600px"}} src={ fetchUrl() } />
        </div>
        
    );

}

export {
    PreviewPane
};
