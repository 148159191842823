
import React from 'react';
import './styles.scss';

export default function LoginRequired({history, redirectUrl}) {

    let thisRedirectUrl = redirectUrl;
    if (!redirectUrl) {
        // Take from pathname
        thisRedirectUrl = window.location.pathname;
    }
    const pushUrl = "/login?redirectUrl=" + encodeURIComponent(thisRedirectUrl);

    return (
        <div className="loginRequired">
            <h3>Access Denied</h3>
            <p>Please <button onClick={() => history.push(pushUrl)}>login</button> to view this page!</p>
        </div>
    )

}