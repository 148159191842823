import React from 'react';
import './styles.scss';
import LanguageFlag from '../LanguageFlag';
import LanguageCodes from '../../languageCodes.json';
import { Text, Toggle } from 'vcc-ui';
//import ButtonToggle from '../ButtonToggle';
import { Link } from 'react-router-dom';
import leftArrow from '../../assets/left-arrow.svg';

class UserEdit extends React.Component {

    getUser() {
        return this.props.user;
    }

    getOrganisations() {
        return Object.values(this.props.organisations);
    }

    getCurrentLangs(orgId) {
        const user = this.getUser();
        let currentLangs = [];
        if (user.permissions && user.permissions.organisations && orgId in user.permissions.organisations && user.permissions.organisations[orgId].localTransLangs) {
            currentLangs = user.permissions.organisations[orgId].localTransLangs;
        }
        return currentLangs;
    }

    getIsMasterEditor(orgId) {
        const user = this.getUser();
        let result = false;
        if (user.permissions && user.permissions.organisations && orgId in user.permissions.organisations && user.permissions.organisations[orgId].isMasterEditor === true) {
            result = true;
        }
        return result;
    }

    getIsUserAdmin() {
        const user = this.getUser();
        let result = false;
        if (user.permissions && user.permissions.isUserAdmin === true) {
            result = true;
        }
        return result;
    }

    getIsDisabled() {
        const user = this.getUser();
        let result = false;
        if (user.disabled === true) {
            result = true;
        }
        return result;
    }

    

    updatePermission(permissionName, permissionKey, permissionValue) {
        this.props.updateUserOrgPermission(null, permissionName, permissionKey, permissionValue)
    }
    updateOrgPermission(organisationId, permissionName, permissionKey, permissionValue) {
        this.props.updateUserOrgPermission(organisationId, permissionName, permissionKey, permissionValue)
    }

    clickedLanguage(organisationId, lang, newStatus) {
        this.updateOrgPermission(organisationId, "localTransLangs", lang, newStatus);
    }

    enableDisableUser(disableUser) {
        this.props.enableDisableUser(disableUser);
    }

    render() {
        const user = this.getUser();
        const isUserAdmin = this.getIsUserAdmin();
        const isDisabled = this.getIsDisabled();

        // const isMasterEditor = this.getIsMasterEditor();
        // const currentLangs = this.getCurrentLangs();
        const orgs = this.getOrganisations();
        
        return (
            <>
                <Link to="/users" className="backBtnWrapper">
                    <div className="backBtn">
                        <img src={leftArrow} alt="back" />
                        <Text as="p" subStyle="emphasis">Back</Text>
                    </div>
                </Link>

                
                <div className="userEdit">
                    <Text as="h2" style={{textAlign: 'center', fontSize: '26px'}}>User Edit</Text>

                    <Text as="h2" className="userInfoHeader" style={{ fontSize: "18px" }} subStyle="emphasis">User info</Text>
                    <div className="userEditInfo">
                        <div>
                            <Text subStyle="emphasis" as="label">IDP: </Text>
                        </div>
                        <div>
                            <Text as="span">{user.idp}</Text>
                        </div>
                    </div>
                    <div className="userEditInfo">
                        <div>
                            <Text subStyle="emphasis" as="label">Unique Ident: </Text>
                        </div>
                        <div>
                            <Text as="span">{user.uniqueIdent}</Text>
                        </div>
                    </div>
                    <div className="userEditInfo">
                        <div>
                            <Text subStyle="emphasis" as="label">Name: </Text>
                        </div>
                        <div>
                            <Text as="span">{user.firstName} {user.lastName}</Text>
                        </div>                        
                    </div>
                    <div className="userEditInfo">
                        <div>
                            <Text subStyle="emphasis" as="label">Email: </Text>
                        </div>
                        <div>
                            <Text as="span">{user.email}</Text>
                        </div>
                    </div>
                    
                    <div className="permissionWrapper">
                        <Text as="h2" style={{ fontSize: "18px" }} subStyle="emphasis">Permissions</Text>

                        <div className="permission toggle">
                            <Text as="p" subStyle="emphasis">User admin</Text>
                            <Toggle
                                checked={isUserAdmin}
                                onChange={() => { this.updatePermission("isUserAdmin", null, isUserAdmin ? false : true) }}
                            />
                        </div>

                        <div className="permission toggle">
                            <Text as="p" subStyle="emphasis">Login disabled</Text>
                            <Toggle
                                checked={isDisabled}
                                onChange={() => { this.enableDisableUser(isDisabled ? false : true) }}
                            />
                        </div>
                    </div>

                    {
                        orgs.map(organisation => {
                            const isMasterEditor = this.getIsMasterEditor(organisation._id);
                            const currentLangs = this.getCurrentLangs(organisation._id);
                            return (
                                <div key={organisation._id} className="permissionWrapper org">
                                    <Text as="h2" style={{ fontSize: "18px" }} subStyle="emphasis">{organisation.name}</Text>
                                    
                                    <div className="permission toggle">
                                        <Text as="p" subStyle="emphasis">Master editor</Text>
                                        <Toggle
                                            checked={isMasterEditor}
                                            onChange={() => { this.updateOrgPermission(organisation._id, "isMasterEditor", null, isMasterEditor ? false : true) }}
                                        />
                                    </div>

                                    <div className="permissionsEdit">
                                        <Text as="h3" style={{fontSize: "16px"}} subStyle="emphasis">Local editor (for languages)</Text>
                                        {(LanguageCodes.map(continent => {
                                            
                                            const langCount = continent.languages.map(l => l.code).filter(n => {
                                                return organisation.languages.indexOf(n) !== -1;
                                            }).length;

                                            if (langCount > 0) {
                                                return (
                                                    <div className="continentsEdit" key={continent.name}>
                                                        <Text as="h4" subStyle="emphasis">{continent.name}</Text>
                                                        <ul className="languageFlags"> 
                                                            {(continent.languages.map(language => {
                                                                if (organisation.languages.indexOf(language.code) !== -1) {
                                                                    const currentValue = currentLangs.indexOf(language.code) !== -1;
                                                                    return (
                                                                        <li key={language.code} className={currentValue?"selectedLanguage":""}>
                                                                            <LanguageFlag languageCode={language.code} name={language.name} flagImage={language.flagImage} clickedLanguage={(lang) => this.clickedLanguage(organisation._id, lang, !currentValue)} />
                                                                        </li>
                                                                    )
                                                                }
                                                                return null;
                                                            }))}
                                                        </ul>
                                                    </div>
                                                )
                                            } else {
                                                return null;
                                            }
                                        }))}
                                    </div>
                                </div>
                            )
                        })
                    }

                        
                        
                    
                </div>
            </>
        )
    }  
}

export default UserEdit;
