import React from 'react';
import {
    withRouter,
    Switch,
    Route,
    // Link
} from "react-router-dom";

import './App.scss';
import { Login } from './components/Login';
import { Menu } from './components/Menu';
import ChecklistList from './components/ChecklistList';
import ChecklistEdit from './components/ChecklistEdit';
import FrontPage from './components/FrontPage';
import NotFound from './components/NotFound';
import DisconnectedBar from './components/DisconnectedBar';
import BackendErrors from './components/BackendErrors';
import { BackendContext } from './components/BackendContext';
import LanguageSelect from './components/LanguageSelect';
import LocalChecklistList from './components/LocalChecklistList';
import LocalChecklistEdit from './components/LocalChecklistEdit';
import Downloads from './components/Downloads';
import Users from './components/Users';
import OrganisationChoice from './components/OrganisationChoice';

// import UserInfo from './components/UserInfo';



class App extends React.Component {

    constructor() {
        super();
        this.state = {
            loginUpdatedAt: null,
            login: null,
            loginError: null,
            connection: null,
            organisationSlug: null, // Determines which organisation we are in
        }
        this.autoLogoutTimeout = null;
    }

    newConnectionStatus(newConnection) {
        this.setState((oldState) => {
            return {
                ...oldState,
                connection: newConnection,
            }
        });
    }

    componentDidMount() {
        // Set public login token ALWAYS
        this.setPublicLoginToken();

        // Startup
        // Start listening for connection status updates
        this.listenRef = this.context.backend.listenConnectionStatus((connection) => {
            this.newConnectionStatus(connection);

            // console.log("We received a connection status update", connection);
            if (connection.status === "CONNECTED") {
                if (connection.meta.token && connection.meta.expiry && connection.meta.user) {
                    // Login was confirmed by the backend, store the token and expiry in local storage
                    // console.log("RECEIVED CONFIRMATION FROM BACKEND THAT TOKEN IS VALID", connection.meta.token, connection.meta.expiry, connection.meta.user);
                    this.saveTokenInLocalStorage(connection.meta.token, connection.meta.expiry);

                    if (connection.meta.expiry !== null) {
                        // We should also set a timeout here so that the autologout occurs
                        const nowTs = new Date().getTime();
                        const expiryTs = new Date(connection.meta.expiry).getTime();
                        const autoExpireIn = expiryTs - nowTs;
                        console.log("Auto expiring token in: " + Math.round(autoExpireIn / 1000) + " seconds");

                        // Reset any existing timeout there may be
                        if (this.autoLogoutTimeout !== null) {
                            clearTimeout(this.autoLogoutTimeout);
                        }

                        this.autoLogoutTimeout = setTimeout(() => {
                            // alert("Auto expire token");
                            this.setLoginToken("public");

                        }, autoExpireIn);
                    }
                }
            }
        });
        // console.log("Ref is: " + this.listenRef);

        // Load the latest token+expiry from localStorage
        const loginItem = window.sessionStorage.getItem("login");
        if (loginItem !== null) {
            const login = JSON.parse(loginItem);
            const expiresAt = new Date(login.userExpiry);
            const now = new Date();
            if (expiresAt < now) {
                // Remove from local storage due to expiry
                // console.log("Removing login token from local storage since it has expired");
                window.sessionStorage.removeItem("login");
                this.setState({
                    loginError: "Your login token has expired.  Please login again!"
                });
            } else {
                // Reuse this one in local storage
                this.setLoginToken(login.userToken, false);
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("Component did update", this.props);

        const pathname = window.location.pathname;
        this.autoSelectOrganisationBasedOnPath(pathname);

        this.autoSelectOrganisationBasedUserPermissions();

        if (prevState.loginUpdatedAt !== this.state.loginUpdatedAt && this.state.login && this.state.login.token) {
            // console.log("Something changed, connecting the backend...");
            // Only if login object has switched from null to not null anymore
            // console.log("**** Login token is NOW set in app state, so it will tell backend singleton to start connecting now...", prevState.loginUpdatedAt, this.state.loginUpdatedAt);
            this.context.backend.setUserToken(this.state.login.token);
            // console.log("Logging in with new token: " + this.state.login.token);
            this.context.backend.connect();

            // Not sure what this does
            /*
            this.context.backend.connect((t,e) => {
                // Login success callback
                console.log("Logging in with new token: " + this.state.login.token); 
                this.saveTokenInLocalStorage(t,e);
            });
            */
        }
    }

    autoSelectOrganisationBasedOnPath(pathname) {
        
        // console.log("autoSelectOrganisationBasedOnPath", pathname);
        
        let matches = null;
        let orgSlug = null;
        if (matches = /^\/([^/]+)\/master/.exec(pathname)) {
            orgSlug = matches[1];

        } else if (matches = /^\/([^/]+)\/local/.exec(pathname)) {
            orgSlug = matches[1];

        } else if (matches = /^\/([^/]+)\/downloads/.exec(pathname)) {
            orgSlug = matches[1];

        } else if (matches = /^\/users/.exec(pathname)) {
            // We dont know the org from the url

        } else if (matches = /^\/login/.exec(pathname)) {
            // We dont know the org from the url

        } else if (matches = /^\/([^/]+)/.exec(pathname)) {
            orgSlug = matches[1];
            
        } else if (pathname === "/") {
            // We dont know the org from the url

        }
        if (orgSlug !== null) {
            // console.log("Choosing org slug: " + orgSlug);
            this.setOrganisation(orgSlug);
        }
    }

    autoSelectOrganisationBasedUserPermissions() {
        // If org is still null (we are probably on a page without :org param in the url), check the logged in user accounts permissions
        // If we just logged in with a valid user account, we can look at the permissions here to make a best guess at which organisation we should preselect.
        if (this.state.organisationSlug === null) {
            if (this.state.connection && this.state.connection.meta && this.state.connection.meta.user && this.state.connection.meta.user.permissions){
                if (this.state.connection.meta.user.permissions.organisations && Object.keys(this.state.connection.meta.user.permissions.organisations).length > 0) {
                    // Orgs
                    const orgs = this.getOrganisations();
                    const orgKeys = Object.keys(this.state.connection.meta.user.permissions.organisations);

                    // Use first
                    if (orgKeys[0] in orgs) {
                        this.setOrganisation(orgs[orgKeys[0]].slug);
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        // Shutdown
        // Stop listening for connection status updates
        // console.log("Stopped listening for connection status updates");
        this.context.backend.unListenConnectionStatus(this.listenRef);
    }

    saveTokenInLocalStorage(loginToken, tokenExpiry) {
        // Store in local storage too so we can easily reload it again
        // console.log("Saving token in local storage since we know it is valid");
        if (loginToken !== "public") {
            window.sessionStorage.setItem("login", JSON.stringify({userToken: loginToken, userExpiry: tokenExpiry}));
        }
    }

    // Sets a NEW login token
    // Clear Login can be set to false to NOT remove the session storage or state (e.g. For auto logging in from sessionStorage)
    setLoginToken(loginToken, clearLogin = true) {
        
        if (clearLogin) {
            this.clearLoginToken();
        }
        this.setState({
            loginUpdatedAt: new Date(),
            loginError: null,
            login: {
                token: loginToken,
            }
        });
        // console.log("Set APP login token", loginToken);
    }

    setPublicLoginToken() {
        // This has just mounted, the login isn't going to be set yet

        // if (this.state.login === null) {
            // console.log("Not logged in, but viewing the public downloads, logging in as public user...");
            this.setLoginToken("public", false);
        // }
    }

    // AKA Logout - Not an error, the user pressed the logout button
    // THERE IS NO SUCH THING AS A LOGOUT
    // You're either logged in or logged in to the public account
    // NEVER redirect here
    clearLoginToken(/*redirect = true*/) {
        // console.log("Logged out", redirect);
        
        this.setState({
            loginError: null,
            login: null,
        });
        window.sessionStorage.removeItem("login");

        this.context.backend.setUserToken(null);
        this.context.backend.shutdown();

        // Also clear auto logout timeout
        if (this.autoLogoutTimeout !== null) {
            clearTimeout(this.autoLogoutTimeout);
            this.autoLogoutTimeout = null;
        }

        /*
        if (redirect) {
            setTimeout(() => {
                this.props.history.push("/login");
            }, 2000);
        }
        */
    }

    setOrganisation(slug) {
        // console.log("SETTING ORGANISATION TO", slug);
        if (this.state.organisationSlug === slug) {
            return;
        }
        this.setState({
            organisationSlug: slug,
        });
    }

    chooseOrganisation(slug) {
        // Set a localStorage variable so this is remembered for next time
        window.localStorage.setItem("preferredOrganisationSlug", slug);

        // The set organisation should be stored in this state
        this.setOrganisation(slug);

        // Now move to the front page of this organisation at /:org
        this.props.history.push("/" + slug);
    }

    getOrganisations() {
        if (this.state.connection) {
            if (this.state.connection.meta) {
                if (this.state.connection.meta.orgs) {
                    return this.state.connection.meta.orgs;
                }
            }
        }
        return {};
    }

    getOrganisation() {
        const orgs = this.getOrganisations();
        const orgsBySlug = {};
        Object.keys(orgs).forEach(orgId => {
            const org = orgs[orgId];
            orgsBySlug[org.slug] = org;
        });
        if (this.state.organisationSlug) {
            if (this.state.organisationSlug in orgsBySlug) {
                return orgsBySlug[this.state.organisationSlug];
            }
        }
        return null;
    }

/*
    getLoggedInUser() {
        if (this.state.connection && this.state.connection.meta && this.state.connection.meta.user) {
            if (this.state.connection.meta.user.idp && this.state.connection.meta.user.idp !== "none") {
                return this.state.connection.meta.user;
            }
        }
        return null;
    }
*/

    render() {

        const organisations = this.getOrganisations();
        const organisation = this.getOrganisation();
        // const WebsiteTitle = <Text as="h2" variant="cook">Volvo Selekt Checklists</Text>
        // const loggedInUser = this.getLoggedInUser();
        /*
        if (this.state.login === null) {
            return (
                <div className="app">
                    <div className="innerAppWrapper">
                        <Menu connection={this.state.connection} clearLoginToken={() => this.clearLoginToken()} />
                        <Switch>
                            <Route exact path="/downloads">
                                <Downloads onMount={() => this.setLoginToken('public', true)} />
                            </Route>
                            <Route render={({match, history}) => (
                                <>
                                    <Text style={{textAlign:'center'}}>{WebsiteTitle}</Text>
                                    <Login errorMessage={this.state.loginError} setLoginToken={(t) => this.setLoginToken(t)} connection={this.state.connection} history={this.props.history} />
                                </>
                            )}/>
                        </Switch>
                    </div>
                </div>
            )
        }
        */

        /*
        const ProtectedRoute = (props) => {
            if (loggedInUser) {
                return <Route {...props} />
            }
            return <LoginRequired history={this.props.history} />
        }
        */

        return (
            
            <div className="app">
                <DisconnectedBar connection={this.state.connection} history={this.props.history} clearLoginToken={() => {this.clearLoginToken(); this.setPublicLoginToken(); }} />
                <BackendErrors />
                <div className="innerAppWrapper">
                    <Menu organisation={organisation} organisationSlug={this.state.organisationSlug} history={this.props.history} connection={this.state.connection} clearLoginToken={() => {this.clearLoginToken(); this.setPublicLoginToken(); }} />
                    <Switch>
                        <Route exact path="/login">
                            
                            <Login errorMessage={this.state.loginError} setLoginToken={(t) => this.setLoginToken(t)} connection={this.state.connection} history={this.props.history} match={this.props.match} clearLoginToken={() => {this.clearLoginToken(); this.setPublicLoginToken(); }} />
                            
                        </Route>

                        {/* PROTECTED ROUTES */}
                        <Route path="/users/:userId?" render={({match, history}) => <Users match={match} history={history} /> } />
                
                        <Route exact path="/:org/master/list" render={({match, history}) => <ChecklistList match={match} history={history} organisation={organisation} /> } />
                            
                        <Route path="/:org/master/edit/:id/:tabId?" render={({match, history}) => <ChecklistEdit match={match} history={history} organisation={organisation} /> } />
                        
                        <Route exact path="/:org/local" render={({match, history}) => <LanguageSelect match={match} history={history} organisation={organisation} connection={this.state.connection} /> } />

                        <Route path="/:org/local/:languageCode/:checklistId/:tabId?" render={({match, history}) => <LocalChecklistEdit match={match} history={history} organisation={organisation} /> } />

                        <Route path="/:org/local/:languageCode" render={({match, history}) => <LocalChecklistList match={match} history={history} organisation={organisation} /> } />
                            

                        {/* PUBLIC ROUTES */}
                        <Route exact path="/:org/downloads" render={({match, history}) => <Downloads match={match} history={history} organisation={organisation} /> } />

                        <Route path="/:org/downloads/:shortCode/:languageCode" render={({match, history}) => <Downloads match={match} history={history} organisation={organisation} /> } />

                        <Route exact path="/:org" render={({match, history}) => <FrontPage match={match} history={history} organisation={organisation} /> } />                

                        <Route exact path="/">
                            <OrganisationChoice orgs={organisations} chooseOrganisation={(slug) => this.chooseOrganisation(slug)} />
                        </Route>
                        
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </div>
            
        );
    }
}

App.contextType = BackendContext;
export default withRouter(App);