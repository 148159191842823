import React from 'react';
import { Text } from 'vcc-ui';
import './styles.scss';

export default function NotFound() {

    return (
        <div className="notFound">
            <Text as="h3" variant="cook">404 - Not Found</Text>

            <p>Sorry.  This page does not exist.</p>
        </div>
    );

}
