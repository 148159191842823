import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';


import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import { StyleProvider, ThemeProvider } from 'vcc-ui';
import volvo from 'vcc-ui/lib/themes/volvo';
import App from './App';

import Bowser from "bowser";
const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowser().name;
console.log("Detected Browser: ", browserName);

let startupMessage = null;
if (browserName === "Internet Explorer") {
  startupMessage = "This web application requires a modern browser and will not function properly with Internet Explorer";
}

if (startupMessage !== null) {
  alert(startupMessage);
} else {
  ReactDOM.render(
    <StyleProvider>
      <ThemeProvider theme={volvo}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </StyleProvider>,
    document.getElementById('root')
  );
}
