import React from 'react';
import './styles.scss';
import { Button } from 'vcc-ui';

const AuthorPane = ({ checksToTranslate, notesToTranslate, variablesToTranslate, authorChecklist, numberOfEditorsApartFromYou }) => {

    return (
        <div className="authorPane">
            {checksToTranslate > 0 && (
                <div className="errorText">Please translate the {checksToTranslate} remaining check row items before authoring this version.</div>
            )}
            {notesToTranslate > 0 && (
                <div className="errorText">Please translate the {notesToTranslate} remaining note items before authoring this version.</div>
            )}
            {variablesToTranslate > 0 && (
                <div className="errorText">Please translate the {variablesToTranslate} remaining variable items before authoring this version.</div>
            )}
            {numberOfEditorsApartFromYou > 0 && (
                <div className="errorText">It looks like other editors are still making changes to this checklists translations.  You cannot author a local checklist if other users are still translating texts.</div>
            )}
            {numberOfEditorsApartFromYou === 0 && checksToTranslate === 0 && notesToTranslate === 0 && variablesToTranslate === 0 && (
                <Button onClick={() => authorChecklist()}>Author</Button>
            )}
        </div>
    );

}

export {
    AuthorPane
};
