import React from 'react';
import './styles.scss';
import { Button } from 'vcc-ui';

const PublishPane = ({ publishChecklistVersion, numberOfEditorsApartFromYou }) => {

    return (
        <div className="publishPane">
            {numberOfEditorsApartFromYou > 0 && (
                <div className="errorText">It looks like other editors are still making changes to this checklist.  You cannot publish a master checklist if other users are still editing it.</div>
            )}
            {numberOfEditorsApartFromYou === 0 && (
                <Button onClick={() => publishChecklistVersion()}>Publish</Button>
            )}
            
        </div>
    );

}

export {
    PublishPane
};
